import { Pipe, PipeTransform, Injectable } from "@angular/core";
  
@Pipe({
  name: "sortKeyValue",
  pure: false
})
export class SortKeyValuePipe implements PipeTransform {
  transform(obj: Object, asc = false): any[] {
    const items = Object.entries(obj).map(([key, value]) => {
      return [key, value];
    });
    return items.sort((a, b) => asc ? a[1] - b[1] : b[1] - a[1]);
  }
}
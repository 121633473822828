import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Params, Router } from '@angular/router';
import { FirebaseError } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  displayError = null;
  redirectAfterLogin = null;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.queryParamMap.subscribe(qp => {
      if (qp.has('redirectAfterLogin')) {
        this.redirectAfterLogin = qp.get('redirectAfterLogin');
      }
      if (qp.has('demoMode')) {
        console.log('demo mode', qp.get('demoMode'));
        this.login(true);

      }
    })
  }

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.email, Validators.required]],
      password: ['', Validators.required]
    });
    this.loginForm.valueChanges.subscribe(vc => {
      console.log(vc, this.loginForm);
    })
  }

  didClickSignup() {
    this.router.navigate(['/auth/signup'], {
      relativeTo: this.route,
      queryParams: this.redirectAfterLogin ? {redirectAfterLogin: this.redirectAfterLogin} : {}}
    );
  }

  async login(demoMode=false) {
    const auth = getAuth();
    try {
      if (demoMode) {
        const res = await signInWithEmailAndPassword(auth, 'demo@chnanalytics.com','demouser');
      } else {
        const vc = this.loginForm.getRawValue();
        const res = await signInWithEmailAndPassword(auth, vc.email, vc.password);
      }
      this.displayError = null;
      this.router.navigate([ this.redirectAfterLogin ? this.redirectAfterLogin : '/']);
    } catch (err) {
      const error = err as FirebaseError
      if (error.code === 'auth/user-not-found') {
        this.displayError = 'Invalid username/password';
      } else if (error.code === 'auth/wrong-password') {
        this.displayError = 'Invalid username/password';
      }
      console.error('Error signing in', error);
      console.error(error.code, error.message, error.name);
    }
  }
  

}

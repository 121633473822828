<div class="support-container">
  <div class="support-content">
    <div class="content-header">
      <div class="tabs-container">
        <div *ngFor="let link of links"
          [ngClass]="{'navbar-tab': true, 'active': router.url.includes('/' + link.route)}"
          (click)="userDidClickTab(link.route)"
        >
          <div class="tab-text">
            {{link.label}}
          </div>
          <div class="border-box"></div>
        </div>
      </div>
    </div>
    <router-outlet></router-outlet>
  </div>
</div>
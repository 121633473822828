<div class="chip-list" [class.nowrap]="nowrap">
  <ng-container *ngFor="let ing of ingredients">
    <ng-container *ngIf="ing && ing.length">
      <div class="chip-container">
        <div class="chip">
          <div class="chip-text">
            {{ing}}
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
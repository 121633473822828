import { Component } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent {
  faqs = [
    ['How can I add a new ingredient to my portfolio?','To add a new ingredient, first select the ‘Support and Appendix’ tab.  Next, select the ‘Support’ tab, and finally the “add new ingredient” button. '],
    ['How can I add an existing ingredient to a group?','To add an ingredient to a group, make sure to select the appropriate group, tab the ingredient, then select the file folder icon to the left of the ingredient. '],
    ['How can I delete an ingredient from a group?','To delete an ingredient from a group, make sure to select the appropriate group, tab the ingredient, then select delete.'],
    ['How can I create a new group?','To create a new group, select the ‘New Group’ tab on the left side panel (directly under the search bar).  This will allow you to input a group name. '],
    ['How do I add or remove ingredients for my favorite group?','Follow the directions for adding or deleting ingredients from a group.'],
    ['How many ingredients does 4Sight track?','4Sight can track thousands of ingredients for a client based on their needs.  Some clients want a wider view of potential risk and opportunity so choose to monitor thousands of ingredients while other clients are more interested in a more targeted list.  '],
    ['What if I want to add an ingredient not tracked by 4Sight?','To add an ingredient not tracked by your custom 4Sight dashboard, you may first follow the instructions to add a new ingredient to your portfolio.  After, your client success manager will alert you to additional time required to load the data and analytics into the dashboard.'],
    ['How long will it take to add an ingredient?','It may take a few days to add a new ingredient to the dashboard.  When your requested ingredient has been processed, your client success manager will let you know. '],
    ['Who should I contact if I forget my user id or password?','You can request a new password and/or your username on the login page located on 4Sight’s website. You may also reach out to your client success manager for assistance. '],
    ['Who should I contact to add another user?','You may reach out to your client success manager to discuss additional users. '],

  ]
}

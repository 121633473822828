import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PhoneNumber } from '@app/models/common.model';
import { PhoneValidator } from '@app/validators/phone.validator';
import { AuthService } from '@core/auth.service';
import { LoadingController } from '@ionic/angular';
import { IUser, User } from '@models/user.model';
import { getAuth, GoogleAuthProvider, signInWithCredential } from 'firebase/auth';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit, OnDestroy {
  signupForm: FormGroup;
  public emailDebouncer;
  public userAlreadyExists = false;
  emailStatus: any;
  error = false;
  loading = false;
  redirectAfterLogin = null;
  subs: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private loadingController: LoadingController
  ) {
    this.route.queryParamMap.subscribe(qp => {
      if (qp.has('redirectAfterLogin')) {
        this.redirectAfterLogin = qp.get('redirectAfterLogin');
      }
    });
  }

  ngOnInit() {

    this.signupForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.email, Validators.required], [this.afEmailValidator.bind(this)]],
      company: ['', Validators.required],
      job_title: ['', Validators.required],
      phoneNumber: ['', [PhoneValidator]],
      passwords: this.fb.group({
        password: ['', [
          Validators.minLength(6),
          Validators.maxLength(25)
        ]],
        confirmPassword: ['', [
          Validators.required,
          this.matchOtherValidator('password')
        ]]
      }),
      // conditionsCheck: ['', [Validators.requiredTrue]],
      // ageCheck: ['', [Validators.requiredTrue]]
    });
    this.signupForm.get('email').valueChanges.subscribe(vc => {
      console.log(this.signupForm.get('email'));
    })

  }

  ngOnDestroy(): void {
    this.subs.forEach(s => {
      if (s) {
        s.unsubscribe();
      }
    })
  }

  didClickSignin() {
    this.router.navigate([ this.redirectAfterLogin ? this.redirectAfterLogin : '/']);
  }

  async onSubmit() {
    console.log('on submit', this.signupForm.getRawValue(), this.signupForm);
    const loader = await this.loadingController.create();
    await loader.present();
    const vc = this.signupForm.getRawValue();
    const email = vc.email;
    const password = vc.passwords.password;
    const user = new User({
      firstName: vc.firstName,
      lastName: vc.lastName,
      email,
      displayName: `${vc.firstName} ${vc.lastName}`,
      phoneNumber: vc.phoneNumber,
      job_title: vc.job_title,
      company: vc.company,
      // phoneNumber: new PhoneNumber({phoneNumber: vc.phoneNumber}),
    })
    try {
      const result = await this.auth.emailSignup(email, password, user);
      console.log('signup result', result);
      await loader.dismiss();
      this.subs.push(this.auth.currentUser$.subscribe(cu => {
        if (cu.isAuthenticated) {
          this.router.navigateByUrl('/')
        }
      }))
      // this.router.navigate([ this.redirectAfterLogin ? this.redirectAfterLogin : '/home']);
      // location.reload();
    } catch (err) {
      console.error('Error signing up', err);
      await loader.dismiss();
      alert("something went wrong")
    }
  }

  // async signInWithGoogle() {
  //   let googleUser: GoogleAuthUser;
  //   try {
  //     googleUser = await GoogleAuth.signIn();
  //     console.log('signed in with Google', googleUser);
  //   } catch (err) {
  //     console.error('Error signin in with google', err);
  //   }
  //   const email = googleUser.email;
  //   let emailAvailable = false;
  //   try {
  //     emailAvailable = await this.auth.isEmailAvailable(email);
  //   } catch (err) {
  //     console.error('Error checking if email was available', err);
  //     return;
  //   }
  //   if (emailAvailable) {
  //     console.log('email is available, creating new user');
  //     const user = new User({
  //       firstName: googleUser.givenName,
  //       lastName: googleUser.familyName,
  //       email,
  //       displayName: `${googleUser.givenName} ${googleUser.familyName}`,
  //     });
  //     let uid = null;
  //     try {
  //       const createUserResponse = await this.auth.emailSignup(email, null, user, true);
  //       console.log('created and signed in to efault account', createUserResponse);
  //       uid = createUserResponse.uid;
  //     } catch (err) {
  //       console.error('Error creating default user account', err);
  //       return;
  //     }
  //     // now sign in using the credentail to link accounts
  //     const credential = GoogleAuthProvider.credential(googleUser.authentication.idToken);
  //     try {
  //       const googleSignInResponse = await signInWithCredential(getAuth(), credential);
  //       console.log('signed in with google', googleSignInResponse);
  //       return;
  //     } catch (err) {
  //       console.error('Error signing in with google', err);
  //     }
  //   } else {
  //     console.log('email is already taken, merging accounts');
  //     return;
  //   }
  // }


  afEmailValidator(control: FormControl): any {
    // async validator to check if input email is already in use
    clearTimeout(this.emailDebouncer);
    return new Promise((resolve, reject) => {
      this.emailStatus = 'loading';
      this.emailDebouncer = setTimeout(() => {
        if (!control.value) { 
          resolve(null); 
        } else {
          this.auth.isEmailAvailable(control.value).then(available => {
            console.log('available', available);
            if (!available) {
              this.emailStatus = 'error';
              resolve({ emailExists: true });
            } else {
              this.emailStatus = 'success';
              resolve(null);
            }
          }).catch(err => {
            console.log('error checking email');
            this.emailStatus = 'error';
            resolve({ error: true });
          });
        }
      }, 1000);
    });
  }

  matchOtherValidator(otherControlName: string) {
    // sync validator to compare password to confirm_password
    let thisControl: FormControl;
    let otherControl: FormControl;
    return function matchOtherValidate(control: FormControl) {
      if (!control.parent) {
        return null;
      }
      // Initializing the validator.
      if (!thisControl) {
        thisControl = control;
        otherControl = control.parent.get(otherControlName) as FormControl;
        if (!otherControl) {
          throw new Error('matchOtherValidator(): other control is not found in parent group');
        }
        otherControl.valueChanges.subscribe(() => {
          thisControl.updateValueAndValidity();
        });
      }
      if (!otherControl) {
        return null;
      }
      if (otherControl.value !== thisControl.value) {
        return {
          matchOther: true
        };
      }
      return null;
    };
  }


}

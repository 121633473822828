import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { getAuth } from 'firebase/auth';
import { authState } from 'rxfire/auth';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthActivateGuard implements CanActivate {
  constructor(
    private router: Router,
  ) {
    console.log('auth guard constructor');
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return authState(getAuth()).pipe(
        tap(x => console.log('auth guard', x)),
        distinctUntilChanged((a, b) => a.uid === b.uid),
        map(cu => {
          if (!cu) {
            console.log('redirect data', next.data)
            console.log('auth-activate guard, no auth', cu);
            this.router.navigateByUrl('/auth');
            return false;
          }
          console.log('in guard, true', cu);
          return true;
        }),
      );
    }
}

export type TCollection = 'users' | 'user_accounts' | 'dashboards' | 'sessions';

export type TAvatarCollection = 'users';
export type TAvatarPathCollection = 'users';
export const AvatarCollection = ['users'];

export const AVATAR_SIZE = '250';
export const THUMBNAIL_SIZE = '64';

export interface ToEntityOptions {
  avatars?: boolean;
  thumbs?: boolean;
}

export const toJson = (obj: any) => {
  return JSON.parse(JSON.stringify(obj));
}
export const toDB = (value: any) => {
  if (value === undefined) {
    return null;
  } else if (value === false) {
    return false;
  } else if (value === null) {
    return null;
  } else if (value instanceof Array) {
    return value;
  } else if (value instanceof(Object)) {
    if (value.toDB) {
      return value.toDB();
    } else {
      const ret = {};
      Object.getOwnPropertyNames(value).forEach(k => {
        ret[k] = toDB(value[k]);
      });
      return ret;
    }
  } else {
    return value;
  }
}
export class Serializable {
  public toJson() {
    const ret: any = {};
    Object.getOwnPropertyNames(this).forEach(key => {
      ret[key] = toJson(this[key]);
    });
    return ret;
  }

  public toDB() {
    const ret = {};
    Object.keys(this).forEach(k => {
      ret[k] = toDB(this[k]);
    })
    return ret;
  }
}

export type PhoneNumberType = 'mobile' | 'work' | 'home';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';
export interface IPhoneNumber {
  phoneNumber: string;
  e164?: string;
  primary?: boolean;
  phoneType?: PhoneNumberType;
  label?: string;
}

export class PhoneNumber extends Serializable implements IPhoneNumber {
  public primary?: boolean;
  public phoneType?: PhoneNumberType;
  public label?: string;
  private _phoneNumber: string;
  private _e164: string;
  get phoneNumber() {
    return this._phoneNumber;
  }
  set phoneNumber(phoneNumber: string) {
    this._phoneNumber = phoneNumber;
    if (phoneNumber && phoneNumber.length > 9) {
      const phoneNumberUtil = PhoneNumberUtil.getInstance();
      try {
        const standardized = phoneNumberUtil.parseAndKeepRawInput(
          phoneNumber, 'US'
        );
        const validNumber = phoneNumberUtil.isValidNumber(standardized);
        if (validNumber) {
          const formatted = phoneNumberUtil.format(standardized, PhoneNumberFormat.E164);
          this._e164 = formatted;
        }
      } catch (err) {
        console.error(`Unable to format phone number ${phoneNumber}`);
        this._e164 = null;
      }
    }
  }
  get e164() {
    return this._e164;
  }

  constructor(obj: Partial<IPhoneNumber>) {
    super();
    const {phoneNumber, phoneType = null, label = null, primary = false} = obj;
    this.phoneNumber = phoneNumber;
    this.phoneType = phoneType;
    this.primary = primary;
    this.label = label;
  }

  public toDB() {
    const ret: IPhoneNumber = {
      phoneNumber: this._phoneNumber,
      e164: this._e164,
    };
    ['label','phoneType','primary'].forEach(k => {
      if (this[k]) ret[k] = this[k];
    });
    return ret;
  }

  public static seedPhoneNumber() {
    return new this({
      phoneNumber: '4407250165',
    });
  }

}

import { Injectable } from '@angular/core';
import { FirestoreService } from './firestore.service';
import { FunctionsService } from './functions.service';
import { AuthService } from './auth.service';
import { FirebaseApp } from '@angular/fire/app';
import { initializeAuth } from '@firebase/auth';
import { HpiService } from './hpi.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  constructor(
    private firestoreService: FirestoreService,
    private functionsService: FunctionsService,
    private authService: AuthService,
    private hpi: HpiService
  ) { }

  appInit() {
    return new Promise(async (resolve, reject) => {
      console.log('initing app');
      await Promise.all([
        this.authService.appInit(),
        this.firestoreService.appInit(),
        this.functionsService.appInit(),
        this.hpi.appInit(),
      ]);
      resolve(null);
    })
  }
}

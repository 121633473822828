import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { Auth, CognitoUser } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(
    private backend: BackendService,
  ) {
    Hub.listen('auth', ({ payload: { event, data, message } }) => {
      switch (event) {
        case 'signIn':
          this.writeLog('logged in');
          break;
      }
    });

  }

  writeLog(message: string) {
    // try {
    //   this.backend.post_route('user_log', {
    //     message
    //   }).then(r => console.log('Logged', message)).catch(err => {
    //     console.error('Error logging message', err);
    //   })
    // } catch (err) {
    //   return;
    // }
  }
}

import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BackendService } from '@app/core/backend.service';
import { startWith } from 'rxjs';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-glossary',
  templateUrl: './glossary.component.html',
  styleUrls: ['./glossary.component.scss']
})
export class GlossaryComponent {
  @ViewChild('paginator') paginator: MatPaginator;
  searchInput = new FormControl();
  
  results = [];
  limit = 25;
  page = 1;
  nHits = 0;
  constructor(
    private backend: BackendService
  ) {
    this.searchInput.valueChanges.pipe(startWith('')).subscribe(vc => {
      this.backend.post_route<{total: number, hits: any[]}>('glossary_search', {
        qs: vc || '',
        limit: this.limit,
        page: 0
      }).then(results => {
        this.nHits = results.total;
        this.results = results.hits;
      })
    })
  }

  getDefinitions() {
    this.backend.post_route<{total: number, hits: any[]}>('glossary_search', {
      qs: this.searchInput.value || '',
      limit: this.limit,
      page: this.page-1
    }).then(results => {
      this.results = results.hits;
    })
  }

  handlePageEvent(e: PageEvent) {
    this.results = null;
    console.log('paged', e);
    this.limit = e.pageSize;
    this.page = e.pageIndex; 
    console.log(this.paginator);
    this.getDefinitions();
  }
}

import { IEntity, Entity } from './entity.model';
type TUserProfileCollection = 'user_profiles';
type TUserCollection = 'users';
type TUserCommonCollection = TUserProfileCollection | TUserCollection;
type TUserAccountCollection = 'user_accounts';

export type TUserRole = 'admin' | 'user';

interface IUserCommon {
  // Default
  collection: TUserCommonCollection;
  publicProperties: string[];
  // Required
  email: string;
  firstName: string;
  lastName: string;
  // Optional
  company?: string;
  job_title?: string;
  phoneNumber?: string;
  /**
   * Inherited from IEntity
   *
   * uid: string;
   * objectID: string // for algolia
   * queryMetadata: string // placeholder for when results is returned by algolia
   * avatar: firebase.storeage.Reference // placeholder
   * avatarMetadata: IAvatarMetadata // placeholder
   * thumbnail: firebase.storage.Referece // placeholder
   * thumbnailMetadata: IAvatarMetadata // placeholder
   * createdAt: Timestamp
   * updatedAt: Timestamp
   * modelVersion: number // in case migration is needed
   * seed: boolean // dev
   */

  /**
  * Inherited from IGeoEntity
  *
  * geo: IGeo // for firestore geo queries
  * address: IAddress
  * _geoloc: IAlgoliaGeo // for algolia geo queries
  */

  /**
   * Inherited from ITagEntity
   *
   * tags: string[] // array of tag ids
   */

}

export interface IUser extends IEntity, IUserCommon {
  collection: TUserCollection;
  email: string;
  firstName: string;
  lastName: string;
  displayName: string;
  phoneNumber?: string;
  company?: string;
  job_title?: string;
}

export class User extends Entity implements IUser {
  public static currentModelVersion = 2.0;
  public static Collection: TUserCollection = 'users';
  public static RequiredProperties = [
    'email',
    'firstName',
    'lastName',
  ];
  public static DefaultAvatar = 'assets/img/default_avatar.png';
  public collection = User.Collection;
  public modelVersion = User.currentModelVersion;
  public publicProperties: string[] = ['email','firstName','lastName','displayName','company','job_title','phoneNumber'];

  // Required
  public email: string;
  public firstName: string;
  public lastName: string;
  public displayName: string;
  public company?: string;
  public job_title?: string;
  public phoneNumber: string;

  constructor(obj: Partial<IUser> = {}) {
    super(obj);
    const {
      // required
      email = null,
      firstName = null,
      lastName = null,
      displayName = null,
      phoneNumber = null,
      company = null,
      job_title = null,
      publicProperties = this.publicProperties,
    } = obj;
    // required
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.displayName = displayName;
    this.phoneNumber = phoneNumber;
    this.company = company;
    this.job_title = job_title;
    if (publicProperties) this.publicProperties = publicProperties;
  }

}


export interface IUserAccount extends IEntity {
  email: string;
  collection: TUserAccountCollection;
  stripeCustomerId: string;
  isAdmin?: boolean;
}

export class UserAccount extends Entity implements IUserAccount {
  public static currentModelVersion = 2.0;
  public static Collection: TUserAccountCollection = 'user_accounts';
  public collection = UserAccount.Collection;
  public modelVersion = UserAccount.currentModelVersion;
  public email: string;
  public isAdmin = false;
  public stripeCustomerId: string;

  constructor(obj: Partial<IUserAccount> = {}) {
    super(obj);
    const {
      email = null,
      stripeCustomerId = null,
      isAdmin = false,
    } = obj;
    this.email = email;
    this.stripeCustomerId = stripeCustomerId;
    this.isAdmin = isAdmin;
  }

}
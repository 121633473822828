import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthActivateGuard } from './guards/auth-activate.guard';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { GroupsComponent } from './modules/groups/groups.component';
import { IngredientsComponent } from './modules/ingredients/ingredients.component';
import { ScienceComponent } from './modules/ingredients/science/science.component';
import { SocialComponent } from './modules/ingredients/social/social.component';
import { SummaryComponent } from './modules/ingredients/summary/summary.component';
import { FaqComponent } from './modules/support/faq/faq.component';
import { GlossaryComponent } from './modules/support/glossary/glossary.component';
import { SupportTabComponent } from './modules/support/support-tab/support-tab.component';
import { SupportComponent } from './modules/support/support.component';
import { AuthComponent } from './modules/auth/auth.component';
import { LoginComponent } from './modules/auth/login/login.component';
import { SignupComponent } from './modules/auth/signup/signup.component';
import { ForgotPasswordComponent } from './modules/auth/forgot-password/forgot-password.component';
import { TrainingComponent } from './modules/support/training/training.component';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    component: AuthComponent,
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      },
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'signup',
        component: SignupComponent
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent
      }
    ]
  },
  {
    path: 'dashboard',
    canActivate: [AuthActivateGuard],
    component: DashboardComponent
  },
  {
    path: 'groups',
    canActivate: [AuthActivateGuard],
    component: GroupsComponent,
  },
  {
    path: 'ingredients',
    component: IngredientsComponent,
    canActivate: [AuthActivateGuard],
    children: [
      {
        path: '',
        redirectTo: 'summary',
        pathMatch: 'full'
      },
      {
        path: 'summary',
        component: SummaryComponent
      },
      {
        path: 'science',
        component: ScienceComponent
      },
      {
        path: 'social',
        component: SocialComponent
      }
    ]
  },
  {
    path: 'appendix',
    canActivate: [AuthActivateGuard],
    component: SupportComponent,
    children: [
      {
        path: '',
        redirectTo: 'faq',
        pathMatch: 'full',
      },
      {
        path: 'glossary',
        component: GlossaryComponent,
      },
      {
        path: 'faq',
        component: FaqComponent,
      },
      {
        path: 'support',
        component: SupportTabComponent
      },
      {
        path: 'training',
        component: TrainingComponent
      }
    ]
  },
  {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { query } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendService } from '@app/core/backend.service';
import { HpiService } from '@app/core/hpi.service';
import { LogService } from '@app/core/log.service';
import { IIngredient, PeriodOptions, TPeriod } from '@app/models/hpi.model';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, map, Subscription, switchMap } from 'rxjs';

type TSortMethod = 'alpha' | 'score:science' | 'score:social' | 'score:change';

@Component({
  selector: 'app-ingredients',
  templateUrl: './ingredients.component.html',
  styleUrls: ['./ingredients.component.scss']
})
export class IngredientsComponent implements OnInit, OnDestroy {
  filteredIngredients: IIngredient[];
  links = [
    {label: 'Summary', route: 'summary'},
    {label: 'Science Page', route: 'science'},
    {label: 'Market Page', route: 'social'},
  ];
  activeLink: string;

  periodOptions = PeriodOptions;

  sortMethod$: BehaviorSubject<TSortMethod>;
  sortDir$: BehaviorSubject<'asc' | 'desc'>;
  queryString$: BehaviorSubject<string> = new BehaviorSubject(null);

  sortOptions: {label: string, value: TSortMethod}[] = [
    {label: 'A-Z', value: 'alpha'},
    {label: 'Science Score', value: 'score:science'},
    {label: 'Market Score', value: 'score:social'},
    {label: 'Score Change', value: 'score:change'},
  ]
  
  ingredientInput: string;
  constructor(
    private backend: BackendService,
    public router: Router,
    public hpi: HpiService,
    private route: ActivatedRoute,
    private log: LogService
  ) {
    if (window.innerWidth < 768) {
      const dialogRef = this.hpi.openMobilePopup();
    }
    const dashParamsStr = sessionStorage.getItem('hpi_dash_params');
    if (dashParamsStr) {
      const {sort, sortDir} = JSON.parse(dashParamsStr);
      this.sortMethod$ = new BehaviorSubject(sort);
      this.sortDir$ = new BehaviorSubject(sortDir);
    } else {
      this.sortMethod$ = new BehaviorSubject('alpha')
      this.sortDir$ = new BehaviorSubject('asc');
    }
  }

  subs: Subscription[] = [];
  period;
  async ngOnInit() {
    this.subs.push(this.hpi.ingParams$.pipe(map(ps => ps.period)).subscribe(p => {
      this.period = p;
    }));
    this.subs.push(combineLatest([
      this.queryString$.pipe(debounceTime(300)),
      // this.sortMethod$.pipe(distinctUntilChanged()),
      // this.sortDir$.pipe(distinctUntilChanged()),
      this.hpi.ingParams$.pipe(map(ps => ps.period)),
    ]).subscribe(async ([qs, period]) => {
      this.filteredIngredients = (await this.backend.post_route<IIngredient[]>('sidebar_ingredients', {
        query_string: qs,
        period,
      })).map(ing => ({
        ...ing,
        science_score: ing.science_score > 0 ? ing.science_score : 2,
        social_score: ing.social_score > 0 ? ing.social_score : 2,
      })).sort((a, b) => this.sortIngredients(a, b));
      // if (!this.hpi.selectedIngredient$.getValue()) {
      //   this.userDidSelectIngredient(this.filteredIngredients[0])
      // }
    }));

    this.subs.push(combineLatest([
      this.sortMethod$.pipe(distinctUntilChanged()),
      this.sortDir$.pipe(distinctUntilChanged()),
    ]).subscribe(([sort, dir]) => {
      sessionStorage.setItem('hpi_dash_params', JSON.stringify({
        sort,
        sortDir: dir,
      }));
      if (this.filteredIngredients && this.filteredIngredients.length) {
        this.filteredIngredients = this.filteredIngredients.sort((a, b) => this.sortIngredients(a, b));
      }
    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => {
      if (s) {
        s.unsubscribe();
      }
    })
  }

  sortIngredients(a: IIngredient, b: IIngredient) {
    const sortDir = this.sortDir$.getValue();
    switch (this.sortMethod$.getValue()) {
      case 'alpha':
        return sortDir === 'asc' ? a.ingredient > b.ingredient ? 1 : -1 : a.ingredient > b.ingredient ? -1 : 1;
      case 'score:science':
        return sortDir === 'asc' ? a.science_score - b.science_score : b.science_score - a.science_score;
      case 'score:social':
        return sortDir === 'asc' ? a.social_score - b.social_score : b.social_score - a.social_score;
      // case 'score:asc':
      //   return (a.science_score + a.social_score) - (b.science_score + b.social_score);
      // case 'score:desc':
      //   return (b.science_score + b.social_score) - (a.science_score + a.social_score);
      case 'score:change':
        const period = this.hpi.period;
        return (Math.abs(b.science_score_delta) + Math.abs(b.social_score_delta)) - (Math.abs(a.science_score_delta) + Math.abs(a.social_score_delta))
      default:
        console.error('Sort sidebar ingredients not implements for sort method: ', this.sortMethod$.getValue());
    }
  }

  userDidSearchIngredients() {
    this.queryString$.next(this.ingredientInput);
  }

  userDidSelectIngredient(ing: IIngredient) {
    console.log('clicked sidebar ingredient', ing.ing_id);
    this.hpi.selectIngredient(ing.ing_id);
    this.log.writeLog(`selected ingredient ${ing.ingredient}`);
  }

  userDidSelectSortMethod(s: TSortMethod) {
    this.sortMethod$.next(s);
    sessionStorage.setItem('hpi_dash_params', JSON.stringify({
      sort: s
    }));
  }

  userDidToggleSortDir() {
    const dir = this.sortDir$.getValue() === 'asc' ? 'desc' : 'asc';
    this.sortDir$.next(dir);
  }

  userDidClickTab(route: string) {
    console.log('clicked tab', route);
    this.router.navigate([route], {
      relativeTo: this.route,
      queryParamsHandling: 'merge'
    });
    this.log.writeLog(`selected ingredients tab ${route}`);
  }

  // userDidSelectPeriod() {
  //   console.log('changed period');
  //   this.hpi.period = this.selectedPeriod;
  //   // this.router.navigate([], {
  //   //   relativeTo: this.route,
  //   //   queryParams: {
  //   //     period: this.selectedPeriod
  //   //   },
  //   //   queryParamsHandling: 'merge'
  //   // })
  // }

  async toggleFavorite(ing: IIngredient) {
    console.log('toggling favorite', ing);
    event.stopPropagation();
    const res = await this.backend.post_route('toggle_favorite_ingredient', {ing_id: ing.ing_id});
    this.hpi.favoriteIngredientIds.push(ing.ing_id);
    console.log('res', res);
  }

}

<!-- <button (click)="switchMode()">Switch Mode</button> -->
<div class="treemap-container">
  <div
    *ngIf="chartOptions"
    echarts
    (chartInit)="onChartInit($event)"
    [loading]="chartLoading"
    style="height: 100%; width: 100%; min-height: 40vh;"
    [options]="chartOptions"
  >
  </div>
</div>
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BackendService } from '@app/core/backend.service';
import { HpiService } from '@app/core/hpi.service';
import { LogService } from '@app/core/log.service';
import { IEffect, TScienceOrSocial } from '@app/models/hpi.model';
import { EChartsOption, EChartsType, TreemapSeriesOption } from 'echarts';
import { combineLatest, map, skipWhile, Subscription } from 'rxjs';

@Component({
  selector: 'app-effect-tree-map',
  templateUrl: './effect-tree-map.component.html',
  styleUrls: ['./effect-tree-map.component.scss']
})
export class EffectTreeMapComponent implements OnInit, OnDestroy {
  @Input() scienceOrSocial: TScienceOrSocial;
  chartOptions: EChartsOption;
  barChartOptions: EChartsOption;
  treemapOptions: EChartsOption;
  treemapChart: EChartsType;
  chartLoading = true;
  mode: 'treemap' | 'bar' = 'treemap';
  sub: Subscription
  constructor(
    private hpi: HpiService,
    private backend: BackendService,
    private log: LogService
  ) {

  }

  async ngOnInit() {
    this.sub = combineLatest([
      this.hpi.ingParams$.pipe(map(ps => ps.period)),
      this.hpi.selectedIngredient$,
    ]).subscribe(([period, ing]) => {
      if (!ing) return;
      this.chartLoading = true;
      this.backend.post_route<IEffect[]>('ingredient_effects', {
        ing_id: ing.ing_id,
        period,
      }).then(res => {
        console.log('effect-tree-map', res);
        this.constructTreeMapOptions(res);
        // this.constructBarChartOptions(res);
        this.chartOptions = this.treemapOptions;
      })
    })
  }
  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  onChartInit(chart) {
    this.treemapChart = chart;
    this.treemapChart.on('dblclick', this.onChartClick.bind(this))
  }

  onChartClick(data: any) {
    console.log('data', data);
    if (data.componentType === 'series') {
      const params = {
        period: this.hpi.period,
        ing_ids: [this.hpi.ing_id],
        title: `${this.hpi.selectedIngredient$.getValue().ingredient} Documents with ${data.data.effect} Effect`,
        eff_ids: [data.data.eff_id],
        science_or_social: this.scienceOrSocial
      }
      this.hpi.openDocDialog(params)
      this.log.writeLog(`Opening doc-dialog from effect-tree-map with params ${JSON.stringify(params)}`);
    }
  }

  constructTreeMapOptions(effects: IEffect[]) {
    function getLevelOption() {
      return [
        {
          itemStyle: {
            borderWidth: 0,
            gapWidth: 5
          }
        },
        {
          itemStyle: {
            gapWidth: 1
          }
        },
        {
          colorSaturation: [0.35, 0.5],
          itemStyle: {
            gapWidth: 1,
            borderColorSaturation: 0.6
          }
        }
      ];
    }
    const bins: IEffect[][] = [[], [], []];
    effects.filter(eff => eff[`doc_count_${this.scienceOrSocial}`] > 0).forEach(eff => {
      const total_pos = eff[`doc_count_${this.scienceOrSocial}_positive`]
      const total_neg = eff[`doc_count_${this.scienceOrSocial}_negative`]
      if (total_pos > total_neg * 1.5) {
        bins[0].push(eff);
      } else if (total_neg > total_pos * 1.5) {
        bins[2].push(eff);
      } else {
        bins[1].push(eff);
      }
    })
    const binNames = ['Mostly Positive','Balanced','Mostly Negative'];
    const binColors = ['green','yellow','red'];
    console.log('bins', bins);
    for (const bin of bins) {
      console.log('bin', bin,  bin.map(a => a[`doc_count_${this.scienceOrSocial}`]), bin.map(a => a[`doc_count_${this.scienceOrSocial}`]).reduce((partialSum, a) => partialSum + a, 0))
    }
    const series: TreemapSeriesOption = {
      type: 'treemap',
      roam: 'pan',
      visibleMin: 1,
      label: {
        color: 'black',
      },
      itemStyle: {
        borderColor: '#fff'
      },
      levels: getLevelOption(),
      data: bins.map((bin, idx) => {
        return {
          name: binNames[idx],
          color: [binColors[idx]],
          value: bin.reduce((partialSum, a) => partialSum + a[`doc_count_${this.scienceOrSocial}`], 0),
          children: bin.map(eff => ({...eff, name: eff.effect, value: eff[`doc_count_${this.scienceOrSocial}`]}))
        }
      })
    }
    const counts = {};

    this.treemapOptions = {
      legend: {},
      tooltip: {
        formatter: (info) => {
          const eff = info.data;
          const pos_count = this.scienceOrSocial === 'science' ? eff.doc_count_science_positive : eff.doc_count_social_positive;
          const neg_count = this.scienceOrSocial === 'science' ? eff.doc_count_science_negative : eff.doc_count_social_negative;
          const pos_label = this.scienceOrSocial === 'science' ? 'Positive Publications' : 'Positive News Mentions';
          const neg_label = this.scienceOrSocial === 'science' ? 'Negative Publications' : 'Negative News Mentions';

          return [
          '<div class="treemap-tooltip">',
            `<div class="treemap-name">${eff.effect}</div>`,
            `<div class="treemap-row"><div class="label">${pos_label}: ${pos_count}<div></div>`,
            `<div class="treemap-row"><div class="label">${neg_label}: ${neg_count}<div></div>`,
          `</div>`
          ].join('')
        }
      },
      series
    };
    this.chartLoading = false;
    console.log("treemap series", series);
  }

  constructBarChartOptions(effects: IEffect[]) {
    const yAxisData = [];
    const overallSeriesData = [];
    const positiveSeriesData = [];
    const negativeSeriesData = [];
    if (this.scienceOrSocial === 'social') {
      effects
        .sort((b, a) => (b.doc_count_social_positive + b.doc_count_social_negative) - (a.doc_count_social_positive + a.doc_count_social_negative))
        .forEach(e => {
          yAxisData.push(e.effect);
          overallSeriesData.push(e.doc_count_social_positive - e.doc_count_social_negative);
          positiveSeriesData.push(e.doc_count_social_positive);
          negativeSeriesData.push(e.doc_count_social_negative * -1);
        });
    } else {
      effects
        .sort((b, a) => (b.doc_count_science_positive + b.doc_count_science_negative) - (a.doc_count_science_positive + a.doc_count_science_negative))
        .forEach(e => {
          yAxisData.push(e.effect);
          overallSeriesData.push(e.doc_count_science_positive - e.doc_count_science_negative);
          positiveSeriesData.push(e.doc_count_science_positive);
          negativeSeriesData.push(e.doc_count_science_negative * -1);
        });
    }
    console.log('****effects', effects);
    console.log('pos series', positiveSeriesData);
    console.log('neg series', negativeSeriesData);
    console.log('overall', overallSeriesData);
    this.barChartOptions = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      dataZoom:[ {
        type: 'inside',
        id: 'insideY',
        yAxisIndex: 0,
        start: 95,
        end: 100,
        zoomOnMouseWheel: false,
        moveOnMouseMove: true,
        moveOnMouseWheel: true
      }],
      legend: {
        data: ['Overall', 'Negative Documents', 'Positive Documents']
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'value'
        }
      ],
      yAxis: [
        {
          type: 'category',
          axisTick: {
            show: false
          },
          data: yAxisData,
        }
      ],
      series: [
        {
          name: 'Overall',
          type: 'bar',
          label: {
            show: true,
            position: 'inside'
          },
          emphasis: {
            focus: 'series'
          },
          data: overallSeriesData
        },
        {
          name: 'Positive Documents',
          type: 'bar',
          stack: 'Total',
          color: 'green',
          label: {
            show: true
          },
          emphasis: {
            focus: 'series'
          },
          data: positiveSeriesData
        },
        {
          name: 'Negative Documents',
          type: 'bar',
          stack: 'Total',
          color: 'red',
          label: {
            show: true,
            position: 'left'
          },
          emphasis: {
            focus: 'series'
          },
          data: negativeSeriesData
        }
      ]
    };
  }

  switchMode() {
    if (this.mode === 'bar') {
      this.mode = 'treemap';
      this.chartOptions = this.treemapOptions;
    } else {
      this.mode = 'bar'
      this.chartOptions = this.barChartOptions;
    }
  }
}

<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>Sign Up</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid>
    <ion-row class="ion-justify-content-center">
      <ion-col class="single-column">
        <form (ngSubmit)="onSubmit()" [formGroup]="signupForm" *ngIf="signupForm" class="signup-form bordered">
          <ion-item lines="full">
            <ion-label position="floating">First name</ion-label>
            <ion-input formControlName="firstName" autofocus="true" autocomplete="given-name" type="text" required></ion-input>
          </ion-item>
          <ion-item lines="full">
            <ion-label position="floating">Last name</ion-label>
            <ion-input formControlName="lastName" autocomplete="family-name" type="text" required></ion-input>
          </ion-item>
          <ion-item lines="full">
            <ion-label position="floating">Phone Number</ion-label>
            <ion-input formControlName="phoneNumber" type="text" autocomplete="tel" mask="(000) 000-0000"></ion-input>
            <!-- <input formControlName="phoneNumber" type="text" prefix="+7" mask="(000) 000 00 00" /> -->
          </ion-item>
          <ion-item lines="full">
            <ion-label position="floating">Company Email</ion-label>
            <ion-input formControlName="email" autocomplete="email" type="email" required></ion-input>
            <ion-note *ngIf="emailStatus" slot="end">
              <!-- <app-circle-check [status]="emailStatus"></app-circle-check> -->
            </ion-note>
          </ion-item>
          <ion-item lines="full">
            <ion-label position="floating">Company</ion-label>
            <ion-input formControlName="company" required autocomplete="company" type="text"></ion-input>
          </ion-item>
          <ion-item lines="full">
            <ion-label position="floating">Job Title</ion-label>
            <ion-input formControlName="job_title" required></ion-input>
          </ion-item>
          <ion-note color="danger" *ngIf="signupForm.get('email')?.errors?.emailExists">There is already an account associated with this email.</ion-note>
          <ng-container formGroupName="passwords">
            <ion-item lines="full">
              <ion-label position="floating">Password</ion-label>
              <ion-input formControlName="password" autocomplete="new-password" type="password" required></ion-input>
            </ion-item>
            <ion-item lines="full">
              <ion-label position="floating">Confirm Password</ion-label>
              <ion-input formControlName="confirmPassword" autocomplete="new-password" type="password" required></ion-input>
            </ion-item>
          </ng-container>
          <ion-row>
            <ion-col>
              <ion-button [disabled]="signupForm.invalid" color="success" expand="block" (click)="onSubmit()">Sign Up</ion-button>
            </ion-col>
          </ion-row>
        </form>
        <!-- <ion-item class="oath-container bordered" lines="full">
          <ion-button (click)="signInWithGoogle()">Sign in with Google</ion-button>
        </ion-item> -->
        <ion-item class="signup-container" lines="full">
          <p>Already have an Account? <a (click)="didClickSignin()">Login</a></p>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BackendService } from '@app/core/backend.service';
import { HpiService, IIngredientParams } from '@app/core/hpi.service';
import { TScienceOrSocial, TSentiment } from '@app/models/hpi.model';
import { addDays, addMonths, format, getMonth, getYear, parse, set, subMonths } from 'date-fns';
import { EChartsOption, EChartsType } from 'echarts';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DocDialogComponent, IDocDialogParams } from '../doc-dialog/doc-dialog.component';
import { NgZone } from '@angular/core';
import { LogService } from '@app/core/log.service';

@Component({
  selector: 'app-doc-timeline',
  templateUrl: './doc-timeline.component.html',
  styleUrls: ['./doc-timeline.component.scss']
})
export class DocTimelineComponent implements OnInit, OnDestroy {
  timelineOptions: EChartsOption;
  timelineLoading = true;
  timelineChart: EChartsType;
  sub: Subscription;
  axisValues: string[];
  openDialog$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  @Input() scienceOrSocial: TScienceOrSocial | 'both' = 'both';

  constructor(
    private hpi: HpiService,
    private backend: BackendService,
    private dialog: MatDialog,
    private log: LogService,
  ) {
  }

  ngOnInit(): void {
    this.openDialog$.subscribe(o => {
      if (o) {
        this.dialog.open(DocDialogComponent, null);
        // this.openDocDialog(new Date(), new Date());
      }
    })
    this.sub = this.hpi.selectedIngredient$.subscribe(ing => {
      if (!ing) {
        return;
      }
      // this.constructTimeline(ps);
      this.backend.post_route('ingredient_time_series', {
        ing_id: ing.ing_id,
        period: this.hpi.period,
      }).then(res => {
        const today = parse('2024-07-01', 'yyyy-MM-dd', new Date());
        // const today = subMonths(new Date(), 2);
        console.log('time series data', today, res);
        let axisValues = [];
        switch (this.hpi.period) {
          case 'All':
            for (let i = 2010; i <= today.getFullYear(); i++) {
              axisValues.push(i);
            }
            break;
          case '1y':
            for (let i = 1; i < 13; i++) {
              const dt = subMonths(today, i);
              axisValues.push(format(dt, 'MMM yyyy'))
            }
            axisValues.reverse()
            break;
        }
        this.axisValues = axisValues;
        this.constructTimeline(res);
      });
    });
  }

  ngOnDestroy(): void {
    // alert('destroying doc-timeline');
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  async constructTimeline(ing) {

    const includeScience = ['science','both'].includes(this.scienceOrSocial);
    const includeSocial = ['social','both'].includes(this.scienceOrSocial);
    
    let scienceMax = 0;
    if (includeScience) {
      for (let v of ing.science_positive_series.concat(ing.science_negative_series)) {
        if (v > scienceMax) {
          scienceMax = v;
        }
      }
    }
    let socialMax = 0;
    if (includeSocial) {
      for (let v of ing.social_positive_series.concat(ing.social_negative_series)) {
        if (v > socialMax) {
          socialMax = v;
        }
      }
    }
    const today = new Date();
    // TODO: make these dynamic

    let series: (echarts.LineSeriesOption | echarts.BarSeriesOption)[] = [];


    if (includeSocial) {
      series = series.concat([
        {
          name: 'Articles (Positive)',
          type: 'bar',
          color: '#6BBD6D',
          data: this.hpi.period === 'All' ? ing.social_positive_series.slice(-5) : ing.social_positive_series,
          xAxisIndex: 0,
          yAxisIndex: 0,
        },
        {
          name: 'Articles (Negative)',
          type: 'bar',
          color: '#EB545D',
          data: this.hpi.period === 'All' ? ing.social_negative_series.slice(-5) : ing.social_negative_series,
          xAxisIndex: 0,
          yAxisIndex: 0,
        }
      ])
    }
    if (includeScience) {
      series = series.concat([
        {
          name: 'Publications (Positive)',
          type: 'line',
          data: this.hpi.period === 'All' ? ing.science_positive_series.slice(-5) : ing.science_positive_series,
          color: '##6BBD6D',
          xAxisIndex: includeSocial ? 1: 0, // move science to lower axis for joint graph
          yAxisIndex: includeSocial ? 1: 0, 
          lineStyle: {
            width: 4
          }
        },
        {
          name: 'Publications (Negative)',
          type: 'line',
          data: this.hpi.period === 'All' ? ing.science_negative_series.slice(-5) : ing.science_negative_series,
          color: '#EB545D',
          xAxisIndex: includeSocial ? 1: 0, // move science to lower axis for joint graph
          yAxisIndex: includeSocial ? 1: 0, 
          lineStyle: {
            width: 4
          }
        }
      ])
    }

    let legendData = [];
    if (includeSocial) {
      legendData = legendData.concat(['Articles (Positive)', 'Articles (Negative)']);
    }
    if (includeScience) {
      legendData = legendData.concat(['Publications (Positive)', 'Publications (Negative)']);
    }
    
    const timelineOptions: EChartsOption = {
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: legendData,
      },
      series
    }
    // x axis options
    timelineOptions.xAxis = [{
      type: 'category',
      data: this.hpi.period === 'All' ? this.axisValues.slice(-5) : this.axisValues,
      gridIndex: 0,
      show: true,
    }];
    if (includeScience && includeSocial) {
      timelineOptions.xAxis.push({
        type: 'category',
        data: this.hpi.period === 'All' ? this.axisValues.slice(-5) : this.axisValues,
        gridIndex: 1,
        show: false
      })
    }

    // y axis options
    if (this.scienceOrSocial !== 'both') {
      timelineOptions.yAxis = [{
        // type: 'value',
        name: includeScience ? 'Science' : 'Market',
        max: includeScience ? scienceMax : socialMax,
        // nameRotate: 90,
        gridIndex: 0,
        // nameTextStyle: {
        //   fontSize: 20
        // },
        // nameLocation: 'middle',
        // nameGap: 40
      }]
    } else {
      timelineOptions.yAxis = [
        {
          type: 'value',
          name: 'Market',
          max: socialMax,
          nameRotate: 90,
          gridIndex: 0,
          nameTextStyle: {
            fontSize: 20
          },
          nameLocation: 'middle',
          nameGap: 40
        },
        {
          type: 'value',
          name: 'Science',
          max: scienceMax,
          nameRotate: 90,
          gridIndex: 1,
          nameTextStyle: {
            fontSize: 20
          },
          nameLocation: 'middle',
          nameGap: 40
        }
      ]
    }

    // grid options
    if (this.scienceOrSocial == 'both') {
      timelineOptions.grid = [
        {
          left: '10%',
          right: '10%',
          bottom: 120
        },
        {
          left: '10%',
          right: '10%',
          height: 70,
          bottom: 10
        }
      ];
    }
    timelineOptions.dataZoom = [{
      type: 'inside',
      
    }]
    timelineOptions.toolbox = {
      feature: {
        saveAsImage: {
          name: 'timeline_' + this.hpi.ingredient.ingredient.replace(/ /g,"_"),
        }
      }
    }
    this.timelineOptions = timelineOptions;
  }

  onChartInit(chart: EChartsType) {
    this.timelineChart = chart;
    this.timelineChart.on('dblclick', this.onChartClick.bind(this));
  }

  onChartClick = (data) => {
    console.log('chart click', data);
    console.log('params', data);
    if (data.componentType === 'series') {
      let startDate = new Date();
      let endDate = new Date();
      let science_or_social: TScienceOrSocial | 'both' = 'both';
      let sentiment: TSentiment = 'all';
      const seriesName = data.seriesName.toLowerCase();
      if (seriesName.includes('publications')) {
        science_or_social = 'science';
      } else if (seriesName.includes('articles')) {
        science_or_social = 'social';
      } else {
        science_or_social = this.scienceOrSocial;
      }
      if (seriesName.includes('positive')) {
        sentiment = 'positive';
      } else if (seriesName.includes('negative')) {
        sentiment = 'negative';
      }
      switch (this.hpi.period) {
        case 'All':
          const year = parseInt(data.name as string);
          startDate = set(startDate, {
            year,
            month: 0,
            date: 1,
            hours: 0,
            minutes: 0,
            seconds: 0,
            milliseconds: 0
          });
          endDate = set(endDate, {
            year: year + 1,
            month: 0,
            date: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
            milliseconds: 0
          })
          break;
        case '1y':
          let dt = parse(data.name, 'MMM yyyy', new Date());
          startDate = set(startDate, {
            year: getYear(dt),
            month: getMonth(dt),
            date: 1,
            hours: 0,
            minutes: 0,
            seconds: 0,
            milliseconds: 0
          });
          dt = addMonths(dt, 1);
          endDate = set(startDate, {
            year: getYear(dt),
            month: getMonth(dt),
            date: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
            milliseconds: 0
          });
          break;
        default:
          throw new Error(`Click event not implements for period ${this.hpi.period}`);
      }
      console.log('after updating dates', this.hpi.period, startDate, endDate);
      this.openDocDialog(startDate, endDate, science_or_social, sentiment);
    }
  }

  openDocDialog(start_date: Date, end_date: Date, science_or_social: TScienceOrSocial | 'both' = 'both', sentiment: TSentiment = 'all') {
    let title;
    switch (this.hpi.period) {
      case 'All':
        title = `${this.hpi.selectedIngredient$.getValue().ingredient} Documents: ${format(start_date, 'MMM yyyy')} - ${format(end_date, 'MMM yyyy')}`
        break;
      case '1y':
        title = `${this.hpi.selectedIngredient$.getValue().ingredient} Documents: ${format(start_date, 'MMM yyyy')}`
        break;
      default:
        throw new Error('openDocDialog not implements for period', this.hpi.period);
    }
    const params: IDocDialogParams = {
      start_date: start_date.getTime() / 1000,
      end_date: end_date.getTime() / 1000,
      ing_ids: [this.hpi.ing_id],
      science_or_social: science_or_social,
      sentiment,
      title
    }
    this.log.writeLog(`Opening doc-dialog from doc-timeline with params ${JSON.stringify(params)}`);
    const dialogRef = this.hpi.openDocDialog(params);
    // const dialogRef = this.dialog.open(DocDialogComponent, {
    //   height: '80vh',
    //   width: '80vw',
    //   data: {
    //     ing_ids: [this.hpi.ing_id],
    //     start_date: start_date.getTime() / 1000,
    //     end_date: end_date.getTime() / 1000,
    //     science_or_social: this.scienceOrSocial,
    //   } as IDocDialogParams
    // });


  }


}

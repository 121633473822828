import { Component } from '@angular/core';
// import { Amplify } from 'aws-amplify';
// @ts-ignore
// import awsExports from '../aws-exports';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from './core/auth.service';
import { LogService } from './core/log.service';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Renderer2 } from '@angular/core'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'hpi-app';
  links = [
    {label: 'DASHBOARD', route: 'dashboard'},
    {label: 'GROUPS', route: 'groups'},
    {label: 'INGREDIENTS', route: 'ingredients'},
    {label: 'SUPPORT AND APPENDIX', route: 'appendix'}
  ];
  isLoggedIn = false;
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public auth: AuthService,
    private log: LogService,
    private renderer: Renderer2
  ) {
  }

  ngOnInit() {
    this.auth.currentUser$.subscribe(cu => {
      this.isLoggedIn = cu.isAuthenticated;
    })
  }


  userDidClickTab(route: string) {
    this.router.navigate([route], {
      relativeTo: this.route,
      queryParamsHandling: 'merge'
    });
    this.log.writeLog(` navigated to ${route}`);
  }

  signout() {
    this.log.writeLog(`signed out`);
    this.auth.signout();
  }

  downloadFile() {
    const link = this.renderer.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', 'https://storage.googleapis.com/hpi-app-dev.appspot.com/docs/4Sight%20Advantage%20Ingredient%20Titanium%20Dioxide.pdf?Expires=1735689600&GoogleAccessId=firebase-adminsdk-qe4zt%40hpi-app-dev.iam.gserviceaccount.com&Signature=Vcl8ftwCkb5jdPvw5Y9ftknkCidRqKGuzgwgPvozyi%2Bp3hcKCwy%2Buh57qnFcSaaUPdH%2Fp4mii5Ttmbts1NeQc4BdJKUApJ9OS6to3xo9i6oUuhb3WKVTotyO7v%2FDPGXqemGBLT%2BVJnxGG1%2BpgDrNZSHNt1sRQAoQwbQ5JVSDnU70RNbB3VUxIIUJQUMhb12X3MQOlh5hPhPhQQIMoaiX6OC%2FGkIz9POFUuJ6DUl6ajKu%2B1WqyR%2BgCjsT0fuTLAvjfzRJEhqm%2Fc%2BGo1UzK1JrbazphSRWRBJpgmzjMiM8n73uHcE8q8ClZlA3uBBmIzEcka5Aki%2FTbERMp1jEZAWyaA%3D%3D');
    link.click();
    link.remove();
 }

}

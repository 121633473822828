<div mat-dialog-title>{{data.mode === 'create' ? 'Create New Group' : 'Update Group'}}</div>
<div mat-dialog-content class="new-ing-bundle-container"  *ngIf="ingredientsControl && bundleNameControl">
  <div class="bundle-body-container" *ngIf="!finished">
    <div class="bundle-body-wrapper">
      <div class="bundle-body-content">
        <div class="bundle-body-name-container">
          <div class="bundle-body-name-label">Group Name</div>
          <input type="text" class="bundle-body-name-input" [formControl]="bundleNameControl">
        </div>
        <div class="bundle-body-search-container">
          <mat-form-field appearance="outline" class="bundle-body-select-container">
            <mat-select multiple [sortComparator]="sortFn" [compareWith]="compareFn" class="bundle-body-select" [formControl]="ingredientsControl" (selectionChange)="onSelectionChange($event)">
              <mat-form-field appearance="fill" class="bundle-body-select-search-container">
                <input class="bundle-body-select-search-input" matInput (keydown)="$event.stopPropagation()" type="text" placeholder="Search Ingredients" (click)="onClickSearch($event)" [formControl]="searchControl">
                <button class="search-input-clear" *ngIf="searchControl?.value?.length" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearchControl()">
                  <mat-icon class="search-input-clear-icon">close</mat-icon>
                </button>
              </mat-form-field>
              <ng-container *ngFor="let ing of filteredIngredients">
                <mat-option [style.display]="ing?.hidden ? 'none' : 'visible'" class="ing-option-container" [value]="ing">
                  <div class="ing-option">
                    <div class="ing-scores">
                      <div [ngClass]="['ing-option-score','bg' + ing.science_score]" matTooltip="Science Score">{{ing.science_score}}</div>
                      <div [ngClass]="['ing-option-score','bg' + ing.social_score]" matTooltip="Market Score">{{ing.social_score}}</div>
                    </div>
                    <div class="ing-name">{{ing.ingredient}}</div>
                  </div>
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <div class="selected-ingredients-container">
            <div class="ing-chip-container" *ngFor="let ing of ingredientsControl?.value">
              <div class="ing-chip">
                <div class="ing-chip-text">
                  {{ing.ingredient}}
                </div>
                <mat-icon class="ing-chip-icon" (click)="removeIngredient(ing)">close</mat-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="name-label"></div>
  </div>
  <div class="bundle-body-container" *ngIf="finished">
    <div class="bundle-body-wrapper">
      <div class="finished-container">
        <div class="finished-icon-container">
          <img class="finished-icon" src="assets/finished_icon.png">
        </div>
        <div class="finished-text-container">
          The group was successfully created!
        </div>
      </div>
    </div>
  </div>
</div>
<div class="bundle-body-actions-container" mat-dialog-actions *ngIf="!finished">
  <div class="bundle-body-actions-wrapper">
    <div class="cancel-button-container" (click)="onCancel()">
      <div class="cancel-button-wrapper">
        <div class="cancel-button-label">
          Cancel
        </div>
      </div>
    </div>
    <div [ngClass]="['create-button-container', createEnabled ? 'enabled' : 'disabled']" (click)="onSubmit()">
      <div class="create-button-wrapper">
        <div class="create-button-label">
          {{this.data.mode === 'create' ? 'Create' : 'Save'}}
        </div>
      </div>
    </div>
  </div>
</div>
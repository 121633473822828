import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthService } from '@app/core/auth.service';
import { BackendService } from '@app/core/backend.service';
import { FirestoreService } from '@app/core/firestore.service';
import { LoadingController } from '@ionic/angular';
@Component({
  selector: 'app-support-tab',
  templateUrl: './support-tab.component.html',
  styleUrls: ['./support-tab.component.scss']
})
export class SupportTabComponent implements OnInit, OnDestroy {
  requestForm: FormGroup;
  showSuccess = false;

  categoryOptions = [
    'Add/Change Ingredient',
    'Data Inquiry',
    'Technical Support',
    'Other',
  ]

  constructor(
    private fb: FormBuilder,
    private backend: BackendService,
    private loadingController: LoadingController,
    private auth: AuthService,
    private fs: FirestoreService,
  ) {

  }

  ngOnInit(): void {
    this.requestForm = this.fb.group({
      category: [],
      description: [''],
    })  
  }

  ngOnDestroy(): void {
      
  }

  async onSubmit() {
    const loader = await this.loadingController.create();
    await loader.present();
    const cu = this.auth.currentUser.data;
    const vc = this.requestForm.getRawValue();
    console.log(vc);
    try {
      await this.backend.post_route('support_request', {
        user_id: cu.uid,
        user_email: cu.email,
        user_name: cu.displayName,
        category: vc.category,
        description: vc.description
      });
      await loader.dismiss();
      this.showSuccess = true;
    } catch (err) {
      await loader.dismiss();
      this.showSuccess = true;
    }
  }

  onClear() {
    this.requestForm.reset();
  }
}

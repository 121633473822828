<div class="popup-container">
  <div class="popup-header">
    <div class="title-container">
      <div class="title">
        Alert!
      </div>
    </div>
  </div>
  
  <div class="warning-container">
    <div class="warning">
        It looks like you are viewing this site on a mobile device.<br /><br />This dashboard was designed to be viewed in a desktop browser, and a mobile version is still in development.<br /><br />Try viewing this page in a browser to access the full capabilities of the dashboard.
    </div>
  </div>
</div>
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-skeleton',
  templateUrl: './card-skeleton.component.html',
  styleUrls: ['./card-skeleton.component.scss']
})
export class CardSkeletonComponent implements OnInit {
  @Input() rows = 1;
  skeletons = []
  constructor() {

  }

  ngOnInit(): void {
    for (let i = 0; i < this.rows; i++) {
      this.skeletons.push(i);
    }
  }
}

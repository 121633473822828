import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { BackendService } from '@app/core/backend.service';
import { HpiService } from '@app/core/hpi.service';
import { LogService } from '@app/core/log.service';
import { IEffect, IIngredient } from '@app/models/hpi.model';

@Component({
  selector: 'app-effects-chip-list',
  templateUrl: './effects-chip-list.component.html',
  styleUrls: ['./effects-chip-list.component.scss'],
})
export class EffectsChipListComponent implements OnInit {
  @Input() eff_ids: string[];
  @Input() ing_id: string;
  @Input() nowrap = true;
  @Input() clickable = true;
  effects: Partial<IEffect>[] = [];
  constructor(
    private backend: BackendService,
    private hpi: HpiService,
    private log: LogService,
  ) {

  }
  
  ngOnInit(): void {
    if (this.eff_ids && this.eff_ids.length) {
      this.backend.post_route<IEffect[]>('ing_definitions', {eff_ids: this.eff_ids}).then(res => {
        this.effects = res;
      });
    }
  }

  async clickEffect(effect: Partial<IEffect>) {
    let ing: IIngredient;
    if (this.ing_id && this.hpi.selectedIngredient$.getValue().ing_id === this.ing_id) {
      ing = this.hpi.selectedIngredient$.getValue();
    } else if (this.ing_id) {
      ing = await this.backend.post_route('ingredient_data', {ing_id: this.ing_id, period: 'All'});
    }
    if (!this.clickable) return;
    const params = {
      title: `${(ing && ing.ing_id) ? ing.ingredient : 'All'} Documents with Effect ${effect.effect}`,
      ing_ids: (ing && ing.ing_id) ? [ing.ing_id] : [],
      eff_ids: [effect.eff_id],
    }
    this.hpi.openDocDialog(params);
    this.log.writeLog(`Opening doc-dialog from effect-chip-list with params ${JSON.stringify(params)}`);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { HpiService } from '@app/core/hpi.service';
import { IIngredient } from '@app/models/hpi.model';
import { distinctUntilChanged, map } from 'rxjs';

@Component({
  selector: 'app-summary-score',
  templateUrl: './summary-score.component.html',
  styleUrls: ['./summary-score.component.scss']
})
export class SummaryScoreComponent implements OnInit {
  @Input() ingredient: IIngredient;
  @Input() scienceOrSocial: 'science' | 'social';
  @Input() orient: 'vertical' | 'horizontal' = 'horizontal';
  score: number;
  delta: number;
  constructor(
    public hpi: HpiService
  ) {
  }

  ngOnInit() {
    this.hpi.ingParams$.pipe(map(ps => ps.period), distinctUntilChanged()).subscribe(period => {
      this.score = this.scienceOrSocial === 'science' ? this.ingredient.science_score : this.ingredient.social_score;
      this.delta = this.scienceOrSocial === 'science' ? this.ingredient.science_score_delta : this.ingredient.social_score_delta;
    })
  }
}

<!-- <div mat-dialog-title>
</div> -->
<div class="doc-dialog-container">
  <div class="documents-header">
    <div class="title-container">
      <div class="title">
        {{params?.title || 'Documents'}}
      </div>
    </div>
    <button (click)="summarize()" mat-raised-button  color="primary">Summarize</button>
    <div class="filters-container">
      <div class="science-social-toggle-container">
        <div *ngFor="let o of scienceOrSocialOptions; let i = index;" [class.active]="params.science_or_social === o.value" class="button-toggle first" (click)="params.science_or_social = o.value; onParamChange(o.value);">{{o.label}}</div>
      </div>
      <div class="sentiment-select-container">
        <label>Sentiment</label>
        <select class="sentiment-select" [(ngModel)]="params.sentiment" (change)="onParamChange($event)">
          <option  *ngFor="let o of sentimentOptions" [value]="o.value">{{o.label}}</option>
        </select>
      </div>
      <div class="sort-select-container">
        <label>Sort</label>
        <select class="sort-select" [(ngModel)]="params.sort" (change)="onParamChange($event)">
          <option *ngFor="let o of sortOptions" [value]="o.value">{{o.label}}</option>
        </select>
      </div>
    </div>
  </div>
  <!-- <div class="sub-header">
    <div class="buttons-container">
    </div>
  </div> -->
  
  <!-- <div class="documents-container{{summary? '-summary': ''}}"> -->
  <div class="documents-container">
    <div class="documents-wrapper" *ngIf="documents">
      <div *ngIf="summary"  class="summary-container">
        <mat-spinner *ngIf="summaryLoading" diameter="60"></mat-spinner>
        <div style="display: flex;">
          <!-- <p *ngIf="!summaryLoading">{{summaryText}}</p> -->
           <div *ngIf="!summaryLoading" [innerHTML]="summaryText"></div>
          <div>
            <mat-icon style="color: red;" (click)="summary=null">times</mat-icon>
          </div>
        </div>
      </div>
      <div class="doc" *ngFor="let doc of documents">
        <div [ngClass]="{'title': true, 'with-link': doc?.science_or_social === 'social' || doc?.links?.length > 0}" (click)="clickDoc(doc)">
          {{doc.title}}<mat-icon *ngIf="doc?.links?.length">open_in_new</mat-icon>
        </div>
        <div class="subtitle">
          <div class="sentiment sentiment-{{doc?.sentiment | tableValue: 'sentiment'}}">{{doc?.sentiment | tableValue: 'sentiment'}}</div>
          <div class="science-or-social {{doc.science_or_social}}">{{doc?.science_or_social === 'science' ? 'Science' : 'Market'}}</div>
          <ng-container *ngIf="doc.science_or_social === 'science'">
            <div class="date" *ngIf="doc.date">{{doc.date | date : 'MMMM y'}}</div>
          </ng-container>
          <ng-container *ngIf="doc.science_or_social === 'social'">
            <div class="date" *ngIf="doc.date">{{doc.date | date : 'longDate'}}</div>
          </ng-container>
          <mat-icon *ngIf="doc?.journal">chevron_right</mat-icon>
          <div class="journal" *ngIf="doc?.journal?.length">{{doc.journal}}</div>
          <mat-icon *ngIf="doc.citations">chevron_right</mat-icon>
          <div class="citations" *ngIf="doc.citations">{{doc.citations}} citations</div>
        </div>
        <div class="effects-container">
          <div *ngIf="doc?.effects?.length" class="label">Effects:</div>
          <app-effects-chip-list [eff_ids]="doc?.eff_ids" [clickable]="false" [ing_id]="hpi.ing_id" [nowrap]="true"></app-effects-chip-list>
        </div>
        <div class="effects-container">
          <div *ngIf="doc?.ingredients?.length" class="label">Ingredients: </div>
          <app-ing-chip-list [ingredients]="doc?.ingredients"></app-ing-chip-list>
        </div>
        <!-- <div class="ext-links-container" *ngIf="doc?.science_or_social === 'social' && doc?.links?.length">
          <div class="label">External Links:</div>
          <a [href]="l" target="_blank" *ngFor="let l of doc?.links; let i = index;">Link {{i}}</a>
        </div> -->
        <div class="abstract">
          <ng-container *ngIf="!doc?.abstract || doc?.abstract === 'NULL'">
            No abstract
          </ng-container>
          <ng-container *ngIf="doc?.abstract && doc?.abstract !== 'NULL'">{{doc.abstract}}</ng-container>
        </div>
      </div>
    </div>
    <div class="documents-wrapper loading" *ngIf="!documents">
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div>
  </div>
  <div class="documents-footer">
    <mat-paginator #paginator
      class="demo-paginator"
      (page)="handlePageEvent($event)"
      [length]="totalDocuments"
      [pageSize]="params.page_size"
      [showFirstLastButtons]="true"
      [pageSizeOptions]="[25, 50, 100]"
      [hidePageSize]="false"
      [pageIndex]="params.page_index"
      aria-label="Select page">
    </mat-paginator>  
  </div>
</div>
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HpiService } from '@app/core/hpi.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  links = [
    {label: 'Glossary', route: 'glossary'},
    {label: 'FAQs', route: 'faq'},
    {label: 'Support', route: 'support'},
    {label: 'Training Guides', route: 'training'}
  ];
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public hpi: HpiService
  ) {
    if (window.innerWidth < 768) {
      const dialogRef = this.hpi.openMobilePopup();
    }
  }

  ngOnInit(): void {
  }

  userDidClickTab(route: string) {
    console.log('clicked tab', route);
    this.router.navigate([route], {
      relativeTo: this.route,
    });
  }


}

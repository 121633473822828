import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BackendService } from '@app/core/backend.service';
import { HpiService } from '@app/core/hpi.service';
import { IIngredient, IIngredientBundle } from '@app/models/hpi.model';
import { combineLatest, debounceTime, startWith, Subscription } from 'rxjs';

@Component({
  selector: 'app-new-ing-bundle-dialog',
  templateUrl: './new-ing-bundle-dialog.component.html',
  styleUrls: ['./new-ing-bundle-dialog.component.scss']
})
export class NewIngBundleDialogComponent implements OnInit, OnDestroy {
  ingredientsControl = new FormControl([]);
  bundleNameControl = new FormControl('');
  searchControl = new FormControl();
  filteredIngredients: IIngredient[];
  finished = false;

  showSaved = false;

  createEnabled = false;

  subs: Subscription[] = [];

  newBundleId: string;

  // selectedIngredients = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {mode: 'update' | 'create', bundle?: IIngredientBundle},
    public dialogRef: MatDialogRef<NewIngBundleDialogComponent>,
    private backend: BackendService,
    private hpi: HpiService
  ) {

  }


  ngOnInit(): void {
    this.subs.push(this.dialogRef.backdropClick().subscribe(() => {
      this.dialogRef.close({bundle_id: this.newBundleId});
    }));
    this.subs.push(
      combineLatest([
        this.ingredientsControl.valueChanges.pipe(startWith(' ')),
        this.bundleNameControl.valueChanges.pipe(startWith(null)),
      ]).subscribe(([ings, name]) => {
        console.log('changed', ings, name);
        this.createEnabled = !!(ings && ings.length && name && name.length);
        console.log('createEnabled', this.createEnabled);
      })
    );
    if (this.data.mode === 'update' || this.data.bundle) {
      // this.ingredientsControl.setValue(this.data.bundle.ingredients.map(ing => `${ing.ing_id}||${ing.ingredient}`));
      this.ingredientsControl = new FormControl(this.data.bundle.ingredients);
      // this.selectedIngredients = this.data.bundle.ingredients;
      this.bundleNameControl.setValue(this.data.bundle.bundle_name);
    }
    this.getIngredients();
    this.subs.push(this.searchControl.valueChanges.pipe(
      debounceTime(200)
    ).subscribe(qs => {
      this.getIngredients();
    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => {
      if (s) s.unsubscribe();
    })
  }

  onSelectionChange(e) {
    console.log('selection changed', e);
  }

  compareFn(a: IIngredient, b: IIngredient) {
    return a.ing_id === b.ing_id;
  }

  sortFn(a, b) {
    return b > a ? 1 : -1;
  }

  async getIngredients() {
    const ingredients = await this.backend.post_route<IIngredient[]>('sidebar_ingredients', {
      query_string: this.searchControl.value
    });
    const ret_ing_ids = ingredients.map(ing => ing.ing_id);
    // const current_ing_ids = this.ingredientsControl.value.map(ing => ing.ing_id);
    
    for (let ing of this.ingredientsControl.value) {
      if (!ret_ing_ids.includes(ing.ing_id)) {
        ingredients.push({...ing, hidden: true});
      }
    }
    this.filteredIngredients = ingredients.sort((a, b) => a.ingredient > b.ingredient ? 1 : -1)

    // this.filteredIngredients = this.ingredientsControl.value.map(ing => ({...ing, hidden: true})).concat(
    //   ingredients
    //   // .filter(ing => !current_ing_ids.includes(ing.ing_id))
    //   .map(ing => ({
    //     ...ing,
    //     ing_tag: `${ing.ing_id}||${ing.ingredient}`
    //   }))
    //   .sort((a, b) => a.ingredient > b.ingredient ? 1 : -1)
    // );
  }

  onClickSearch(e) {
    console.log('clicked search');
  }

  clearSearchControl() {
    this.searchControl.setValue('');
  }

  async onSubmit() {
    if (this.data.mode === 'create') {
      await this.createBundle();
    } else {
      await this.updateBundle();
    }
  }

  async createBundle() {
    if (!this.createEnabled) {
      return;
    }
    const params = {
      'bundle_name': this.bundleNameControl.value,
      'ing_ids': this.ingredientsControl.value.map(ing => ing.ing_id),
    }
    console.log('params', params);
    const res = await this.backend.post_route<{success: Boolean, bundle_id: string}>('create_ing_bundle', params);
    console.log('res', res);
    if (res.success) {
      this.newBundleId = res.bundle_id;
      await this.hpi.getUserIngBundles();
      this.finished = true;
    } else {
      alert('Something went wrong');
    }
  }

  async updateBundle() {
    if (!this.createEnabled) {
      return;
    }
    const params = {
      'bundle_name': this.bundleNameControl.value,
      'ing_ids': this.ingredientsControl.value.map(ing => ing.ing_id),
      'bundle_id': this.data.bundle.bundle_id
    }
    console.log('params', params);
    const res = await this.backend.post_route<{success: Boolean, bundle_id: string}>('update_ing_bundle', params);
    console.log('res', res);
    if (res.success) {
      this.newBundleId = res.bundle_id;
      await this.hpi.getUserIngBundles();
      // this.finished = true;
    } else {
      alert('Something went wrong');
    }
  }

  removeIngredient(ing_tag: string) {
    console.log('remove ingredient', ing_tag);
    const ings = (this.ingredientsControl.value as string[]);
    const idx = ings.indexOf(ing_tag);
    if (idx > -1) {
      ings.splice(idx, 1);
      this.ingredientsControl.setValue(ings);
    }
  }

  onCancel() {
    this.dialogRef.close({bundle_id: this.newBundleId});
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocTimelineComponent } from './doc-timeline.component';
import { NgxEchartsModule } from 'ngx-echarts';



@NgModule({
  declarations: [
    DocTimelineComponent
  ],
  imports: [
    CommonModule,
    NgxEchartsModule,
  ],
  exports: [
    DocTimelineComponent
  ]
})
export class DocTimelineModule { }

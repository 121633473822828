import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, distinct, distinctUntilChanged, iif, lastValueFrom, map, shareReplay, skipWhile, switchMap, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { getAuth, getIdToken, onAuthStateChanged } from 'firebase/auth';
import { authState } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class BackendService {
  jwt: string;
  constructor(
    private http: HttpClient,
  ) {

  }

  get_jwt() {
    return new Promise((resolve, reject) => {
      if (this.jwt) {
        resolve(this.jwt)
      } else {
        authState(getAuth()).subscribe(user => {
          user.getIdToken().then(jwt => {
            this.jwt = jwt;
            resolve(this.jwt);
          })
        })
      }
    })
  }

  async es(body: any, indices = 'techscouting_clusters') {
    const stream = this.http.post(`${environment.backendUrl}/es`, {
      body,
      indices
    });
    const ret = await lastValueFrom(stream);
    // console.log('es response', ret);
    return ret;
  }

  async get_route<T>(route, params = {}) {
    const stream = this.http.get(`${environment.backendUrl}/${route}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.jwt
      },
      params,
    });
    const ret = await lastValueFrom(stream);
    // console.log(route, ' response', ret);
    return ret as T;
  }

  
  async post_route<T>(route: string, body = {}) {
    await this.get_jwt();
    const stream = this.http.post(`${environment.backendUrl}/${route}`, body, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.jwt
      }
    });
    const ret = await lastValueFrom(stream);
    console.log('posted route', route, ' response', ret);
    return ret as T;
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectTreeMapComponent } from './effect-tree-map.component';
import { NgxEchartsModule } from 'ngx-echarts';



@NgModule({
  declarations: [
    EffectTreeMapComponent
  ],
  imports: [
    CommonModule,
    NgxEchartsModule
  ],
  exports: [
    EffectTreeMapComponent
  ]
})
export class EffectTreeMapModule { }

import { Pipe, PipeTransform, Injectable } from "@angular/core";
  
@Pipe({
  name: "sort",
  pure: false
})
export class SortPipe implements PipeTransform {
  transform(items: any[], key: string): any[] {
    if (!items || !items.length) return items;
    if (typeof(items[0][key] === 'string')) {
      if (key === 'value') {
        console.log('sorted string items', items.sort((a, b) => a[key] - b[key]));
      }
      return items.sort((a, b) => a[key] - b[key])
    } else {
      return items.sort((a, b) => b[key] - a[key])
    }
  }
}
<!-- <ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>Log In</ion-title>
  </ion-toolbar>
</ion-header> -->
<ion-content>
  <ion-grid>
    <ion-row class="ion-justify-content-center">
      <ion-col class="single-column">
        <form class="login-form bordered" *ngIf="loginForm" [formGroup]="loginForm">
          <ion-item lines="full">
            <ion-ripple-effect></ion-ripple-effect>
            <ion-label position="floating">Email</ion-label>
            <ion-input formControlName="email" [clearOnEdit]="true" enterkeyhint="next" [autofocus]="true" [clearInput]="true" autocomplete="email" type="email" required></ion-input>
          </ion-item>
          <ion-item lines="full">
            <ion-label position="floating">Password</ion-label>
            <ion-input formControlName="password" type="password" autocomplete="current-password" required [clearInput]="true"></ion-input>
          </ion-item>
          <ion-item *ngIf="displayError">
            <p class="error-message">{{displayError}}</p>
          </ion-item>
          <ion-row>
            <ion-col>
              <ion-button type="button" (click)="login()" [disabled]="loginForm.invalid" color="success" expand="block">Sign In</ion-button>
              <a [routerLink]="['/auth/forgot-password']" class="small-text">Forgot Password?</a>
            </ion-col>
          </ion-row>
        </form>
        <ion-item class="signup-container bordered" lines="full">
          <p>Don't have an account? <a style="cursor: pointer;" (click)="didClickSignup()">Sign Up for Free</a></p>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { BackendService } from '@app/core/backend.service';
import { HpiService } from '@app/core/hpi.service';
import { IEffect, TPeriod } from '@app/models/hpi.model';
import { combineLatest, distinctUntilKeyChanged, from, lastValueFrom, merge, skipWhile, startWith, Subscription, switchMap, take } from 'rxjs';
import { IDocDialogParams } from '../doc-dialog/doc-dialog.component';
import { LogService } from '@app/core/log.service';
import { set } from 'date-fns';

@Component({
  selector: 'app-effects-table',
  templateUrl: './effects-table.component.html',
  styleUrls: ['./effects-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class EffectsTableComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  isLoadingResults = true;
  dataSource: MatTableDataSource<IEffect>;
  columnsToDisplay = [
    'effect',
    'doc_count_social_positive',
    'doc_count_social_negative',
    'doc_count_science_positive',
    'doc_count_science_negative',
  ];
  columnLabels = {
    eff_id: 'eff_id',
    effect: 'Effect',
    definition: 'Cluster Name',
    doc_count_social_positive: 'Market (+)',
    doc_count_social_negative: 'Market (-)',
    doc_count_science_positive: 'Science (+)',
    doc_count_science_negative: 'Science (-)',
  }
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: IEffect;

  ing_id: string;
  period: TPeriod;

  sub: Subscription;
  

  constructor(
    private backend: BackendService,
    private hpi: HpiService,
    private log: LogService
  ) {

  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  async ngOnInit() {
    this.isLoadingResults = true;
    this.sub = this.hpi.ingParams$.subscribe(async ps => {
      const res = await this.backend.post_route<IEffect[]>('ingredient_effects', {
        ing_id: ps.ing_id,
        period: ps.period,
        sort_dir: 'desc',
        sort_col: 'doc_count_science_negative',
        limit: 5
      });
      this.dataSource = new MatTableDataSource(res);
      this.isLoadingResults = false;
      this.dataSource.sort = this.sort;
      console.log('at end of ngOnINit', this.sort);
    });
  }

  ngAfterViewInit(): void {
    this.sub = combineLatest([
      this.hpi.ingParams$,
      this.sort.sortChange
    ])
    .pipe(
      switchMap(([ps, sort]) => {
        console.log('sub fired', ps, sort)
        let sort_col = 'doc_count_social_negative';
        let sort_dir = 'desc';
        if (sort) {
          sort_col = sort.active;
          sort_dir = sort.direction;
        }

        this.isLoadingResults = true;
        return from(this.backend.post_route<IEffect[]>('ingredient_effects', {
          ing_id: ps.ing_id,
          period: ps.period,
          sort_dir,
          sort_col,
          limit: 5
        }));
      })
    )
    .subscribe(res => {
      console.log('new data', res);
      this.dataSource.data = res;
    })
  }

  selectEffect(eff: IEffect) {
    console.log('selected effect', eff);
    window.open(`https://meshb-prev.nlm.nih.gov/record/ui?ui=${eff.eff_id}`, '_blank')
  }

  // TODO: remove
  tmp() {
    console.log('expandedElement changed', this.expandedElement);
  }

  announceSortChange(e) {
    console.log('sort change announced', e, this.sort);
  }

  loadEffects() {

  }

  clickItem(row, item) {

  }

  clickCell(column: string, element: IEffect) {
    console.log('element', column, element)

    let startDate = new Date();
    startDate = set(startDate, {
      year: 2010,
      month: 0,
      date: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0
    });
    const params: IDocDialogParams = {
      ing_ids: [this.hpi.ing_id],
      eff_ids: [element.eff_id],
      title: '',
      science_or_social: 'both',
      period: this.hpi.period,
      start_date: startDate.getTime() / 1000
    }
    let title;
    if (column.includes('science')) {
      params.science_or_social = 'science';
    } else if (column.includes('social')) {
      params.science_or_social = 'social';
    }
    if (column.includes('positive')) {
      params.sentiment = 'positive';
    } else if (column.includes('negative')) {
      params.sentiment = 'negative';
    }
    params.title = `${this.hpi.selectedIngredient$.getValue().ingredient} Documents with Effect ${element.effect}`;
    this.hpi.openDocDialog(params);
    this.log.writeLog(`Opening doc-dialog from effects-table with params ${JSON.stringify(params)}`);
  }
}

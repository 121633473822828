import { Component, OnDestroy, OnInit } from '@angular/core';
import { BackendService } from '@app/core/backend.service';
import { HpiService } from '@app/core/hpi.service';
import { IHpiDocument } from '@app/models/hpi.model';
import { EChartsOption, TreemapSeriesOption } from 'echarts';
import { distinctUntilKeyChanged, Subscription } from 'rxjs';
@Component({
  selector: 'app-science',
  templateUrl: './science.component.html',
  styleUrls: ['./science.component.scss']
})
export class ScienceComponent implements OnInit, OnDestroy {

  treemapOptions: EChartsOption;
  treemapLoading = true;

  documents: IHpiDocument[];
  sub: Subscription;

  scienceScoreDelta = 0;
  constructor(
    public hpi: HpiService,
    private backend: BackendService,
  ) { }

  async ngOnInit() {
    this.sub = this.hpi.sessionData$.subscribe(async sd => {
      await this.constructBox();
      if (!sd.ingredient) {return;}
      this.scienceScoreDelta = sd.ingredient.science_score_delta;
      this.backend.post_route<IHpiDocument[]>('ingredient_documents', {
        ing_id: sd.ingredient.ing_id,
        ingredient: sd.ingredient.ingredient,
        science_or_social: 'science',
        period: sd.params.period,
      }).then(res => {
        this.documents = res;
      })
    });
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  async constructBox() {
    // const docs = this.hpi.selectedIngredient$.getValue().documents.filter(d => d.science_or_social === 'science');
    const docs = [];
    const counts = {}
    docs.forEach(doc => {
      const c = doc.concern;
      if (!counts[c]) {
        counts[c] = 1
      } else {
        counts[c] += 1
      }
    });
    const series: TreemapSeriesOption = {
      type: 'treemap',
      roam: 'pan',
      data: Object.entries(counts as {[k: string]: number}).map(([name, value]) => {
        return {name, value}
      }).sort((a, b) => b.value - a.value)
    }

    this.treemapOptions = {
      // title: {
      //   text: 'Concerns Deep Dive (hover for detail)'
      // },
      series
    };
    this.treemapLoading = false;
  }

  clickDoc(doc: IHpiDocument) {
    if (doc.links.length) {
      window.open(doc.links[0], '_blank')
    }
  }



}

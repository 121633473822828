import { Injectable, inject } from '@angular/core';
import { FirebaseApp } from 'firebase/app';
import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions';
import * as fps from '@app/models/functions';
import { Functions } from '@angular/fire/functions';

interface IFunkshin<TFunkshinParams, TFunkshinResponse>{
  execute: (params: TFunkshinParams) => Promise<TFunkshinResponse>;
}
class Funkshin<TFunkshinParams, TFunkshinResponse> implements IFunkshin<TFunkshinParams, TFunkshinResponse>{
  execute: (params: TFunkshinParams) => Promise<TFunkshinResponse>;
  constructor(private funkshinName: string, private functions: Functions) {
    this.execute = async (params: TFunkshinParams) => {
      try {
        console.log('invoking ', funkshinName, params);
        const res = await httpsCallable<TFunkshinParams, TFunkshinResponse>(this.functions, this.funkshinName)(params);
        return res.data as TFunkshinResponse;
      } catch (err) {
        console.log('function params', params);
        console.error(`Error in ${funkshinName}: ${err}`);
        throw new Error(`${err}`);
      }
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class FunctionsService {
  functions: Functions = inject(Functions);
  constructor(
    // private functions: Functions
  ) {
    // connectFunctionsEmulator(getFunctions(), 'localhost', 5001);
  }

  public async appInit() {
    return;
  }

  public endpoints = {
    // auth
    auth: {
      isEmailAvailable: 'auth-isEmailAvailable',
    },
    // user
    users: {
      createUser: 'users-createUser',
      deleteUser: 'users-deleteUser',
      updateUser: 'users-updateUser',
      updateUserAccount: 'users-updateUserAccount',
    },
    // stripe
    stripe: {
      getCustomer: 'stripe-getCustomer',
      createCustomerPortalSession: 'stripe-createCustomerPortalSession',
      cancelSubscription: 'stripe-cancelSubscription',
      addCustomerPaymentMethod: 'stripe-addCustomerPaymentMethod',
      getCustomerPaymentMethods: 'stripe-getCustomerPaymentMethods',
      deletePaymentMethod: 'stripe-deletePaymentMethod',
      setDefaultPaymentMethod: 'stripe-setDefaultPaymentMethod',
      createCheckoutSession: 'stripe-createCheckoutSession',
      createCustomerSetupIntent: 'stripe-createCustomerSetupIntent',
      getCustomerSetupIntents: 'stripe-getCustomerSetupIntents'
    },
    push: {
      //push
      addGcmToken: 'push-addGcmToken',
      sendTestPushNotification: 'push-sendTestPushNotification',
      deleteGcmToken: 'push-deleteGcmToken',
    },
    storage: {
      // storage
      storageGetFiles: 'storage-storageGetFiles',
      storageCopyFile: 'storage-storageCopyFile',
      storageDeleteFile: 'storage-storageDeleteFile',
    },
  };

  // user
  public users = {
    createUser: () => new Funkshin<fps.users.CreateUserParams,fps.users.CreateUserResponse>(this.endpoints.users.createUser, this.functions),
    deleteUser: () => new Funkshin<fps.users.DeleteUserParams, fps.users.DeleteUserResponse>(this.endpoints.users.deleteUser, this.functions),
    updateUser: () => new Funkshin<fps.users.UpdateUserParams,fps.users.UpdateUserResponse>(this.endpoints.users.updateUser, this.functions),
    updateUserAccount: () => new Funkshin<fps.users.UpdateUserAccountParams,fps.users.UpdateUserAccountResponse>(this.endpoints.users.updateUserAccount, this.functions),
  }

  // auth
  public auth = {
    isEmailAvailable: () => new Funkshin<fps.auth.IsEmailAvailableParams, fps.auth.IsEmailAvailableResponse>(this.endpoints.auth.isEmailAvailable, this.functions),
  };
  // org
  public stripe = {
    getCustomer: () => new Funkshin<fps.stripe.GetCustomerParams, fps.stripe.GetCustomerResponse>(this.endpoints.stripe.getCustomer, this.functions),
    cancelSubscription: () => new Funkshin<fps.stripe.CancelSubscriptionParams, fps.stripe.CancelSubscriptionResponse>(this.endpoints.stripe.cancelSubscription, this.functions),
    addCustomerPaymentMethod: () => new Funkshin<fps.stripe.AddCustomerPaymentMethodParams, fps.stripe.AddCustomerPaymentMethodResponse>(this.endpoints.stripe.addCustomerPaymentMethod, this.functions),
    // addCard: () => new Funkshin<fps.stripe.AddCardParams, fps.stripe.AddCardResponse>(this.endpoints.stripe.addCard, this.functions),
    getCustomerPaymentMethods: () => new Funkshin<fps.stripe.GetCustomerPaymentMethodsParams, fps.stripe.GetCustomerPaymentMethodsResponse>(this.endpoints.stripe.getCustomerPaymentMethods, this.functions),
    deletePaymentMethod: () => new Funkshin<fps.stripe.DeletePaymentMethodParams, fps.stripe.DeletePaymentMethodParams>(this.endpoints.stripe.deletePaymentMethod, this.functions),
    setDefaultPaymentMethod: () => new Funkshin<fps.stripe.SetDefaultPaymentMethodParams, fps.stripe.SetDefaultPaymentMethodResponse>(this.endpoints.stripe.setDefaultPaymentMethod, this.functions),
    createCheckoutSession: () => new Funkshin<fps.stripe.CreateCheckoutSessionParams, fps.stripe.CreateCheckoutSessionResponse>(this.endpoints.stripe.createCheckoutSession, this.functions),
    createCustomerPortalSession: () => new Funkshin<fps.stripe.CreateCustomerPortalSessionParams, fps.stripe.CreateCustomerPortalSessionResponse>(this.endpoints.stripe.createCustomerPortalSession, this.functions),
    createCustomerSetupIntent: () => new Funkshin<fps.stripe.CreateCustomerSetupIntentParams, fps.stripe.CreateCustomerSetupIntentResponse>(this.endpoints.stripe.createCustomerSetupIntent, this.functions),
    getCustomerSetupIntents: () => new Funkshin<fps.stripe.GetCustomerSetupIntentsParams, fps.stripe.GetCustomerSetupIntentsResponse>(this.endpoints.stripe.getCustomerSetupIntents, this.functions),

  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ing-chip-list',
  templateUrl: './ing-chip-list.component.html',
  styleUrls: ['./ing-chip-list.component.scss']
})
export class IngChipListComponent {
  @Input() ingredients: string[];
  @Input() nowrap = true;

}

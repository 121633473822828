<div class="groups-container" *ngLet="hpi.sessionData$ | async as sd;">
  <div class="groups-sidebar">
    <div class="groups-sidebar-header-container">
      <div class="groups-sidebar-title-container">
        <div class="groups-sidebar-title-wrapper">
          <div class="groups-sidebar-title-text">GROUPS</div>
          <div class="groups-sidebar-title-icon-container">
            <mat-icon class="groups-sidebar-title-icon" matTooltip="Groups of ingredients">info_outline</mat-icon>
          </div>
        </div>
      </div>
      <div class="groups-sidebar-search-container">
        <input class="groups-sidebar-search-input" placeholder="&#xF002; Search Groups" style="font-family:Arial, FontAwesome" [(ngModel)]="groupSearchInput" (input)="searchChanged()">
      </div>
    </div>
    <div class="groups-sidebar-body">
      <div class="groups-list-wrapper">
        <div class="groups-list">
          <div class="new-group-container" (click)="userDidClickNewGroup()">
            <div class="new-group-icon-container">
              <mat-icon class="new-group-icon">add</mat-icon>
            </div>
            <div class="new-group-label-container">
              <div class="new-group-label">Create a new group</div>
            </div>
          </div>
          <div [ngClass]="['group-container', selectedBundle?.bundle_id === bundle.bundle_id ? 'active' : 'inactive', 'group-' + bundle.mean_score]" *ngFor="let bundle of hpi.ingredientBundles" (click)="userDidSelectBundle(bundle)">
            <div class="group-wrapper">
              <div class="group-header-container">
                <div class="group-header-top">
                  <div class="group-header-title-container">
                    <div class="group-header-title">{{bundle.bundle_name}}</div>
                  </div>
                  <div class="group-header-icon-container">
                    <mat-icon class="group-header-icon" [matMenuTriggerFor]="bundleMenu">more_vert</mat-icon>
                    <mat-menu #bundleMenu="matMenu">
                      <button mat-menu-item (click)="onEditBundle(bundle)">
                        <mat-icon>settings</mat-icon>
                        <span>Edit Group</span>
                      </button>
                      <button *ngIf="bundle.bundle_name !== 'Favorites'" mat-menu-item (click)="onDeleteBundle(bundle)">
                        <mat-icon class="delete-icon">delete</mat-icon>
                        <span class="delete-label">Delete Group</span>
                      </button>
                    </mat-menu>
                  </div>
                </div>
                <div class="group-header-bottom">
                  {{bundle.ingredients?.length}} ingredients
                </div>
              </div>
              <div class="group-body-container">
                <div class="group-body-wrapper">
                  <div class="group-body-left">
                    <div class="group-body-left-label">
                      Level of concern
                    </div>
                    <div [ngClass]="['group-body-left-value-container', 'bg' + bundle.mean_score]">
                      <div class="group-body-left-value">
                        <ng-container *ngIf="bundle.mean_score === 0">N/A</ng-container>
                        <ng-container *ngIf="bundle.mean_score === 1">Low</ng-container>
                        <ng-container *ngIf="bundle.mean_score === 2">Medium</ng-container>
                        <ng-container *ngIf="bundle.mean_score >= 3">High</ng-container>
                      </div>
                    </div>
                  </div>
                  <div class="group-body-right">
                    <div class="group-body-right-label">
                      New Effects
                    </div>
                    <div class="group-body-right-value">
                      {{bundle.num_new_effects}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="groups-content">
    <div class="groups-content-header">
      <div class="filters-container">
        <div class="period-filter-container">
          <div class="period-filter-label">
            Period:
          </div>
          <select class="period-filter-select" [(ngModel)]="hpi.period">
            <option *ngFor="let periodOption of periodOptions" [value]="periodOption.value">{{periodOption.label}}</option>
          </select>
        </div>
        <div class="divider"></div>
        <div class="filter-menu-container">
          <div class="filter-menu-label">Filters</div>
          <img class="filter-menu-icon" src="assets/filter_icon.png">
        </div>
      </div>
    </div>
    <div class="groups-body-container" *ngIf="selectedBundle && selectedBundle?.ingredients?.length">
      <div class="ingredient-table-container card-container">
        <div class="card-header">
          <div class="card-title">
            Group Ingredients
          </div>
        </div>
        <div class="card-content no-pad">
          <div class="ingredient-table">
            <div class="ingredient-table-header ingredient-row">
              <div></div>
              <div>INGREDIENT</div>
              <div>SCIENCE SCORE<mat-icon class="help-icon" matTooltip="Number of effects associated with this ingredient that are associated with positive sentiment">info_outline</mat-icon></div>
              <div>MARKET SCORE<mat-icon class="help-icon" matTooltip="Effects associated with this ingredient">info_outline</mat-icon></div>
              <div>PUBLICATIONS <mat-icon class="help-icon" matTooltip="Number of science documents for this ingredient that exhibit positive sentiment">info_outline</mat-icon></div>
              <div>NEWS ARTICLES <mat-icon class="help-icon" matTooltip="Number of mews articles for this ingredient that exhibit positive sentiment">info_outline</mat-icon></div>
            </div>
            <div class="ingredient-row data-row" *ngFor="let ing of selectedBundle.ingredients; let i = index;">
              <div class="ing-checkbox"><mat-checkbox></mat-checkbox></div>
              <div class="ingredient-label" (click)="clickIngredient(ing)">{{ing.ingredient}}</div>
              <div class="ing-score">
                <div class="ing-score-wrapper">
                  <div [ngClass]="['sb-score-value', 'bg' + ing.science_score]">{{ing.science_score}}</div>
                  <div class="sb-score-delta-wrapper" *ngLet="ing?.science_score_delta as delta">
                    <img class="sb-score-delta-arrow" *ngIf="delta > 0" src="assets/delta_pos.png">
                    <img class="sb-score-delta-arrow" *ngIf="delta < 0" src="assets/delta_neg.png">
                    <div class="sb-score-delta" *ngIf="delta !== 0">{{delta < 0 ? delta * -1 : delta}}</div>
                  </div>                      
                </div>
              </div>
              <div class="ing-score">
                <div class="ing-score-wrapper">
                  <div [ngClass]="['sb-score-value', 'bg' + ing.social_score]">{{ing.social_score}}</div>
                  <div class="sb-score-delta-wrapper" *ngLet="ing?.social_score_delta as delta">
                    <img class="sb-score-delta-arrow" *ngIf="delta > 0" src="assets/delta_pos.png">
                    <img class="sb-score-delta-arrow" *ngIf="delta < 0" src="assets/delta_neg.png">
                    <div class="sb-score-delta" *ngIf="delta !== 0">{{delta < 0 ? delta * -1 : delta}}</div>
                  </div>                      
                </div>
              </div>
              <div>{{ing.doc_count_science}}</div>
              <div>{{ing.doc_count_social}}</div>
            </div>
          </div> 
        </div>
      </div>
      <div class="group-treemap-container card-container">
        <div class="card-header">
          <div class="card-title">
            Top Effects
            <mat-icon class="tooltip-icon" matTooltip="Effects associated with all ingredients in selected group grouped by sentiment">info_outline</mat-icon>
          </div>
        </div>
        <div class="card-content no-pad treemap-wrapper">
          <div
            *ngIf="treemapOptions"
            echarts
            [loading]="treemapLoading"
            style="height: 100%; width: 100%; min-height: 40vh;"
            [options]="treemapOptions"
          >
        </div>
        </div>
      </div>
    </div>
    <div class="select-bundle" *ngIf="!selectedBundle || !selectedBundle?.ingredients?.length">
      <ng-container *ngIf="!selectedBundle">
        Select group from sidebar
      </ng-container>
      <ng-container *ngIf="selectedBundle && !selectedBundle?.ingredients?.length">
        Selected group has no ingredients
      </ng-container>
    </div>
  </div>
</div>
<div class="chip-list" [class.nowrap]="nowrap">
  <ng-container *ngFor="let eff of effects">
    <ng-container *ngIf="eff.effect?.length && eff?.eff_id && !['no concern'].includes(eff.effect.toLowerCase())">
      <div class="chip-container" (click)="clickEffect(eff)" [class.clickable]="clickable" [matTooltip]="eff?.definition || 'N/A'">
        <div class="chip">
          <div class="chip-text">
            {{eff.effect}}
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
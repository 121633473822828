<div class="effects-table-container">
  <h2 style="display: grid; justify-self: center;" *ngIf="!dataSource || !dataSource.data.length">No Known Effects</h2>
  <table 
    mat-table 
    [dataSource]="dataSource"
    matSort
    matSortActive="doc_count_social_negative"
    matSortDirection="desc"
    (matSortChange)="announceSortChange($event)"
    multiTemplateDataRows 
  >
    <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
      <th mat-header-cell mat-sort-header [sortActionDescription]="'Sort by ' + columnLabels[column]" *matHeaderCellDef> {{columnLabels[column]}} </th>
      <td mat-cell [class.data-cell]="true" *matCellDef="let element" (click)="clickCell(column, element)"> {{element[column]}} </td>
    </ng-container>
    <ng-container matColumnDef="expand">
      <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button aria-label="expand row" (click)="(expandedElement = expandedElement?.eff_id === element.eff_id ? null : element); $event.stopPropagation(); tmp()">
          <mat-icon *ngIf="expandedElement?.eff_id !== element.eff_id">keyboard_arrow_down</mat-icon>
          <mat-icon *ngIf="expandedElement?.eff_id === element.eff_id">keyboard_arrow_up</mat-icon>
        </button>
      </td>
    </ng-container>
    
    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
        <div class="example-element-detail"
            [@detailExpand]="element?.eff_id === expandedElement?.eff_id ? 'expanded' : 'collapsed'">
            <div class="link" style="color: blue; cursor: pointer;" (click)="selectEffect(element)">View More</div>
            <div class="definition">
              {{element?.definition}}
            </div>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;"
        class="example-element-row"
        [class.example-expanded-row]="expandedElement?.eff_id === element.eff_id"
        (click)="expandedElement = expandedElement?.eff_id === element.eff_id ? null : element">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>

</div>

<div class="glossary-container">
  <div class="glossary-wrapper">
    <div class="search-input-container">
      <input class="search-input" type="text" placeholder="&#xF002; Search Glossary" [formControl]="searchInput" style="font-family:Arial, FontAwesome">
    </div>
    <div class="results-container">
      <div class="result-container" *ngFor="let r of results">
        <div *ngIf="r.effect" class="effect-result-container">
          <div class="effect-name">{{r.effect}}</div>
          <div class="effect-def">{{r?.definition?.length ? r.definition : 'No definition found'}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="results-footer">
    <mat-paginator #paginator
      class="demo-paginator"
      (page)="handlePageEvent($event)"
      [length]="nHits"
      [pageSize]="limit"
      [showFirstLastButtons]="true"
      [pageSizeOptions]="[25, 50, 100]"
      [hidePageSize]="false"
      [pageIndex]="page"
      aria-label="Select page">
    </mat-paginator>  
  </div>
</div>
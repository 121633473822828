import { Component } from '@angular/core';

@Component({
  selector: 'app-mobile-popup',
  templateUrl: './mobile-popup.component.html',
  styleUrls: ['./mobile-popup.component.scss']
})
export class MobilePopupComponent {

}

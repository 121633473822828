import { Injectable, NgZone, resolveForwardRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IIngredient, IIngredientBundle, TPeriod } from '@app/models/hpi.model';
import { DocDialogComponent, IDocDialogParams } from '@app/modules/doc-dialog/doc-dialog.component';
import { MobilePopupComponent } from '@app/modules/mobile-popup/mobile-popup.component';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, from, map, Observable, skipWhile, startWith, switchMap, tap } from 'rxjs';
import { BackendService } from './backend.service';
import { AuthService } from './auth.service';
import { authState } from '@angular/fire/auth';
import { getAuth } from 'firebase/auth';

export interface IIngredientParams {
  ing_id: string,
  period: TPeriod;
}

@Injectable({
  providedIn: 'root'
})
export class HpiService {
  scoreColors = ['blue', '#2D822F', '#ADC034', '#FFD763', '#FF9E44', '#BC2134'];
  ingParams$: BehaviorSubject<IIngredientParams> = new BehaviorSubject<IIngredientParams>({ing_id: null, period: 'All'});
  selectedIngredient$: BehaviorSubject<IIngredient> = new BehaviorSubject<IIngredient>(null);
  selectedIngredientDistinct$: Observable<IIngredient>;

  sessionData$: Observable<{ingredient: IIngredient, params: IIngredientParams}>;
  ingredientBundles: IIngredientBundle[];
  favoriteIngredients = [];
  favoriteIngredientIds = [];
  loadingIngredient = false;

  get ingredient() {
    return this.selectedIngredient$.getValue();
  }

  get ing_id() {
    return this.ingParams$.getValue().ing_id;
  }
  set ing_id(ing_id: string) {
    this.ingParams$.next({
      ...this.ingParams$.getValue(),
      ing_id,
    })
  }
  get period() {
    return this.ingParams$.getValue().period;
  }
  set period(period: TPeriod) {
    this.ingParams$.next({
      ...this.ingParams$.getValue(),
      period
    });
  }



  constructor(
    private backend: BackendService,
    private dialog: MatDialog,
    private zone: NgZone,
    // private auth: AuthService
  ) {
  }

  async appInit() {
    console.log('hpi service app init');
    const ssParamsStr = sessionStorage.getItem('hpi_params');
    if (ssParamsStr) {
      this.ingParams$.next(JSON.parse(ssParamsStr));
    }
    this.ingParams$.pipe(
      tap(() => {
        this.selectedIngredient$.next(null);
      }),
      skipWhile(ps => !ps.ing_id),
      tap(() => this.loadingIngredient = true),
      tap(ps => console.log('params firing', ps)),
      distinctUntilChanged((a, b) => {
        return a.ing_id === b.ing_id && a.period === b.period;
      }),
      tap(ps => sessionStorage.setItem('hpi_params', JSON.stringify(ps))),
      switchMap(ps => this.backend.post_route<IIngredient>('ingredient_data', {
        ing_id: ps.ing_id,
        period: this.period
      })),
    ).subscribe(ingredient => {
      this.selectedIngredient$.next(ingredient);
      this.loadingIngredient = false;
    });


    this.selectedIngredientDistinct$ = this.selectedIngredient$.pipe(
      skipWhile(ing => !ing),
      startWith(null),
      distinctUntilChanged((a, b) => !a || a.ing_id === b.ing_id)
    );

    this.sessionData$ = combineLatest([
      this.ingParams$,
      this.selectedIngredient$
    ]).pipe(
      map(([params, ingredient]) => ({params, ingredient}))
    )

    await this.getUserIngBundles()
    return;
  }

  async selectIngredient(ing_id: string) {
    if (this.ingParams$.getValue().ing_id === ing_id) return;
    this.ingParams$.next({
      ...this.ingParams$.getValue(),
      ing_id
    });
    // this.router.navigate(['/ingredients'], {
    //   // relativeTo: this.route,
    //   queryParams: {
    //     ingredient: ingredient
    //   }
    // });
  }

  async getUserIngBundles() {
    combineLatest([
      this.ingParams$.pipe(map(ps => ps.period)).pipe(
        distinctUntilChanged()
      ),
      authState(getAuth()).pipe(
        skipWhile(u => !u || !u.uid),
        distinctUntilChanged((a, b) => a.uid === b.uid)
      ),
    ])
    .subscribe(async ([period, user_id]) => {
      const bundles = await this.backend.post_route<IIngredientBundle[]>('get_user_ing_bundles', {period});
      if (bundles.length) {
        this.ingredientBundles = bundles.map(b => ({
          ...b,
          created_at: new Date(b.created_at),
          updated_at: new Date(b.updated_at),
        })).sort((a, b) => b.created_at.getTime() - a.created_at.getTime());
        for (const b of this.ingredientBundles) {
          if (b.bundle_name === 'Favorites') {
            this.favoriteIngredients = b.ingredients;
            this.favoriteIngredientIds = this.favoriteIngredients.map(ing => ing.ing_id);
            console.log('favorites', this.favoriteIngredientIds);
            break;
          }
        }
      }
    })
  }

  openDocDialog(params: IDocDialogParams) {
    this.zone.run(() => {
      const dialogRef = this.dialog.open<DocDialogComponent, IDocDialogParams>(DocDialogComponent, {
        height: '80vh',
        width: '80vw',
        data: params
      });
      return dialogRef;
    })
  }

  openMobilePopup() {
    this.zone.run(() => {
      const dialogRef = this.dialog.open<MobilePopupComponent>(MobilePopupComponent, {
        height: '50vh',
        width: '80vw',
      });
      return dialogRef;
    })
  }
}

<div [ngClass]="['summary-score-wrapper', 'summary-score-' + orient]">
  <div class="values">
    <div [ngClass]="['score-big', 'score-' + score]">{{score}}</div>
    <div class="sb-score-delta-wrapper" [matTooltip]="delta === 0 ? '' : ((hpi?.ingParams$ | async)?.period === 'All' ? 'Change in score over last 2 years' : 'Change in score over last 6 months.')" >
      <img class="sb-score-delta-arrow" *ngIf="delta < 0" src="assets/delta_pos.png">
      <img class="sb-score-delta-arrow" *ngIf="delta > 0" src="assets/delta_neg.png">
      <div class="sb-score-delta" *ngIf="delta !== 0">{{delta < 0 ? delta * -1 : delta}}</div>
    </div> 
  </div>
  <div [ngClass]="['score-chip', 'bg' + score]">
    <div [ngClass]="['score-chip-text', 'text-' + score]">
      <ng-container *ngIf="score === 1">Positive</ng-container>
      <ng-container *ngIf="score === 2">Neutral</ng-container>
      <ng-container *ngIf="score === 3">Caution</ng-container>
      <ng-container *ngIf="score === 4">Concerning</ng-container>
      <ng-container *ngIf="score === 5">Negative</ng-container>
    </div>
  </div>
</div>
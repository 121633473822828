import { KeyValue } from '@angular/common';
import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss']
})
export class TrainingComponent {
  originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return 0;
  }
  dev_videos = {
    'How to Navigate the Dashboard': 'https://storage.googleapis.com/hpi-app-dev.appspot.com/media/dashboard%20navigation.mp4?Expires=1735689600&GoogleAccessId=firebase-adminsdk-qe4zt%40hpi-app-dev.iam.gserviceaccount.com&Signature=BzzXnY40Rj7FBsS7x5JfdnvXNbzjE43IbwlmJX3x5E2ZIuRMiVGTqD%2BE2z0wq0IXIuMuApP9GdIbSAHVUm0DNj7S4HtcAev02iFjxCCo8Ls5j16hAliJ3r4tq5jravS06VwEui9Uk16Y60QPApwQmS0%2FPz3C11cGQl9X43JcmyNNDxvedY7yAb9SkkTm8tzKjWLOgEKV3XilGOAGL7JBP4%2FKvHgK8ncSBv3HTySqYSMZtlIhbTe1%2BEMoW8Lqrzjmaf2hW%2B3BlFIqRyp71f%2B8%2FOo3X2gh%2BZGScq16m1zOXOO7kF7OyCfup9RTe0GQ0AlKdA21SHTL4xs0edA%2FgGjsWA%3D%3D',
    'How to Respond to Alerts': 'https://storage.googleapis.com/hpi-app-dev.appspot.com/media/How%20to%20Respond%20to%20Alerts%20in%20FutureSet%20Dashboard.mp4?Expires=1735689600&GoogleAccessId=firebase-adminsdk-qe4zt%40hpi-app-dev.iam.gserviceaccount.com&Signature=Z8fpZZt0zD3N2NtqJu4SmGfpIFACAMBYeq3Mfdsehf8SyfxKH83I0NYwm9ATfQenrE8vUmJUYMr4%2FwN0AFS5QvM34wqvHXsEaLgrj%2BgJeYRy6pqs0PmunNFXv%2FD9aEuUheAZwrL%2FbNISCWZFMEDeqE0FbiHjE3iWc0%2B7Du0AxDhz2PfSuv8e%2F2n6%2BmTytTGc%2BCwPUETzGtP21IscKLmS0bKj3%2FS20SSnkt1c9GtNmTCGbXJyJReu0cep65T%2BIyXQX3UkrC10QBHUdtZPZH6l4FZKpMHXrGbVf1L0Jr9ry9KFw3TVqeiAb2QNM4EPakSwJhwNoRcLYpREFWcX8RgDSw%3D%3D',
    'How to Create Ingredient Groups': 'https://storage.googleapis.com/hpi-app-dev.appspot.com/media/Group%20Creation.mp4?Expires=1735689600&GoogleAccessId=firebase-adminsdk-qe4zt%40hpi-app-dev.iam.gserviceaccount.com&Signature=qU83L5fIS8p2YbXYvuMivzfgb%2BCIHlI%2FtiM1deZpMKUO7HA8Ze06pfYTepC5YZCS2eJZ%2BIvDoN6tszCykXIpkFJIymFU53UpHZzkdc6qZbXbbsGwtljfsxL5Hz%2BnSZ37rC9Fvl5wsW%2Bd9BANadP2SQ1E4eoLWREfKDNQfgAYyu9Zzuv8H6d0bYToqWYM8d3i%2F3pcyRtIj2BL6gmfigMgc7EOYQUmcXkRieqBtLX5vRWhUdfd%2Be7SPio5DZnIG3BlYkMMKjS6pyGr%2BNbL0pV%2BjhIfJlixwjeg8P0USIEFTe%2F7%2FnRlbXqPmwd33ijerOiNTtUfcDyIFlSlMPnEqM3uWg%3D%3D',
    'Summarizing Documents': 'https://storage.googleapis.com/hpi-app-dev.appspot.com/media/Summarizing%20Documents.mp4?Expires=1735689600&GoogleAccessId=firebase-adminsdk-qe4zt%40hpi-app-dev.iam.gserviceaccount.com&Signature=ShDB%2F4fmcygwYmsztLhP2rVcgIWqmkWR3hcy3iNQbmAEgbIH07tuAXwQwNXWQY5tngU6o%2BhufgRNozCJJ86YckvJa5dMqukhMV%2F%2FMDQCgv5mInrzLGujQDoSnvYFHajwfnLtQRZMZCSId5hFGN8Stz%2BXreCW6lSLiGeP1CCkpAphaC2rgp9rvpIzYs0%2FHYJIh4WNyoONrRlHfNGCo85HobzP4q0O94bgqrWZ8KLY9HCVn4BMNAW5oJlZSMJk4ejuvq25MEXdgFzg8FT%2BfLXszQGeKXTA%2BqPBsiUnLyzXJyYO7WLCTfwR1LAH0okeQ7v3tBDu2O2JwyKmQa9K%2BluoWg%3D%3D'
    
  };
  prod_videos = {
    'How to Navigate the Dashboard': 'https://storage.googleapis.com/hpi-app-prod.appspot.com/media/dashboard%20navigation.mp4?Expires=1735689600&GoogleAccessId=firebase-adminsdk-ayfdu%40hpi-app-prod.iam.gserviceaccount.com&Signature=GR4WF0A5ODw2H9r%2BC8Ua%2F5scnDUfjsmqfsxfzXBqjACCmtBMn37vRFOzZ376Nr44L6sYS0uAA1TLAK23C149OIcQTAI%2B1I%2BeLkQPKcJk132HPZf%2BDr7ojvZGsmdTUUylM3byq%2BHSDu%2FiBUSHk9oah5xTbcrTBiOMnL3F2ksnvgVF3br378Ewk%2FVRea5DCWOnljxnO2h5HehmlDrxOQbnyo%2Fcl8SnjBJmTjEPWQz4GYO5VAagjb9F%2Ff5l6QbyGgn1B9Tt7ExoDvp3vJy%2FeG06VoY2%2B%2FKCx3n6ZrCp1UR2TRDbiVucDpS2ur6OiDoU0rC9OHy28umB8qVey%2BMzWdDMlA%3D%3D',
    'How to Create Ingredient Groups': 'https://storage.googleapis.com/hpi-app-prod.appspot.com/media/Group%20Creation.mp4?Expires=1735689600&GoogleAccessId=firebase-adminsdk-ayfdu%40hpi-app-prod.iam.gserviceaccount.com&Signature=xxOhQ8s0i24B0QAF34ZL6NtpWaUGzvt4FI5v8UNT2aZO%2FUpv4qGyFPv7pA6orUUVoq9CN9luYLRpmiclBk%2BT5sUrNPM0H0vPIQKLM%2BAwM%2FvDlrN7F3AY7SSzEt7iZigRSy9JHhiQuk1sUb1Sj6iK4dn%2B84ojQjp3SmyXBNMABG2ID2pfbGtfn1PnBYqYpzyMyoiKrU%2BwNXNo%2B9IHUpcixcmYd2Y3JnQY6saU7EzQTNdqM3LTpvYGmXrS%2F2zB1y%2BJiE1A3JGooh9U0KnhZtLov7IeRtXGJ1J9DSfyzQWnt3YW%2B9NpLPf1LvwGLEzf%2FuBX7%2BVH26iOkPeqFRFpy8o3hQ%3D%3D',
    'How to Respond to Alerts': 'https://storage.googleapis.com/hpi-app-prod.appspot.com/media/How%20to%20Respond%20to%20Alerts%20in%20FutureSet%20Dashboard.mp4?Expires=1735689600&GoogleAccessId=firebase-adminsdk-ayfdu%40hpi-app-prod.iam.gserviceaccount.com&Signature=Wjp0xht1mjzjtt761QCJ%2BZFPW%2FeohUPUocxXlOQRKpyadpzFMuAd21Ua%2BituCPzQoaAyCOANT4ApQnMfd2KhFrfbFrTpK15bJ3Crr70pyebCva%2F9OS7MwXuNknQfUB3ONqC8CBvvDX47hmYljjJ05w5abq8XXcG%2FvbplX%2BFyPt4%2FBbpNY5ZF%2FT%2F4lP13bDOn8UIr9WHG2R8c3aDBlkyK7kxe%2BJl4aNsI6EPrdSJiZo54iiEcI4xMCBjdDD%2FUEtPGW5faXvXlLSWL04M6TFYRQvLweLmYxXO4NA2aYglNic96h00%2BMHenMvemC9c3UyoWKb7WYqWQqXbmNGlXzcj7eg%3D%3D',
    'Summarizing Documents': 'https://storage.googleapis.com/hpi-app-prod.appspot.com/media/Summarizing%20Documents.mp4?Expires=1735689600&GoogleAccessId=firebase-adminsdk-ayfdu%40hpi-app-prod.iam.gserviceaccount.com&Signature=M35Y1%2Fk2plQFy8sEWpt5N3xFGPeW5sGWMvBLI%2FL%2FEx073wFOUvlOAY0ICKf0481iZAV0dpYAjaI9tVny4Kbu4nlSd%2ByCoz9iWQLv9VN5QSBcdGGg3Vyc1qtvg9IzmThlQpZ8GyBSCv8D1wTgIScsEDfVJ8kFSSRDYK1ElJz9t01%2F0%2ByogdztFFmaTGTM29GnkUpHIwPhMI7pIXnvOJ4tG%2FlEOw%2FotyANN2F0K7qe896D0BEruoXCalQRrMhbFaJIVTLW246chB3Afj6PuQvE%2BYpbeUZtlHMjrynw2i0uDqCAytWDwGOEVVLJOqIvgwFF%2FI10BBnCBDkFp3aoXwS%2FOA%3D%3D'
  };
  videos;
  constructor(
  ) {
    this.videos = environment.production ? this.prod_videos : this.dev_videos;
  }

  toggleVideo() {

  }
}

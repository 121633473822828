<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>Reset Your Password</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid>
    <ion-row class="ion-justify-content-center">
      <ion-col class="single-column bordered">
        <ion-item lines="full">
          <ion-label position="floating">Email</ion-label>
          <ion-input [(ngModel)]="email" autocomplete="email" type="email" required></ion-input>
        </ion-item>
        <ion-row>
          <ion-col>
            <ion-button (click)="onSubmit()" [disabled]="!email.length" type="submit" color="danger" expand="block">Send</ion-button>
          </ion-col>
        </ion-row>
        <small>
          Please provide the email address that you used when you signed
          up for your 4Sight account.
        </small>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
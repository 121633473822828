import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BackendService } from '@app/core/backend.service';
import { HpiService } from '@app/core/hpi.service';
import { IEffect, IIngredient, IIngredientBundle, PeriodOptions } from '@app/models/hpi.model';
import { EChartsOption, TreemapSeriesOption } from 'echarts';
import { BehaviorSubject } from 'rxjs';
import { NewIngBundleDialogComponent } from './new-ing-bundle-dialog/new-ing-bundle-dialog.component';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit {
  groupSearchInput: string;
  queryString$: BehaviorSubject<string> = new BehaviorSubject(null);
  selectedBundle: IIngredientBundle;
  periodOptions = PeriodOptions;

  treemapOptions: EChartsOption;
  treemapLoading = true;

  constructor(
    private backend: BackendService,
    public hpi: HpiService,
    private dialog: MatDialog,
    private router: Router
  ) {
    if (window.innerWidth < 768) {
      const dialogRef = this.hpi.openMobilePopup();
    }
  }

  ngOnInit(): void {
  }

  searchChanged() {
    this.queryString$.next(this.groupSearchInput);
  }

  userDidClickNewGroup() {
    console.log('new group');
    const dialogRef = this.dialog.open(NewIngBundleDialogComponent, {
      minWidth: '497px',
      minHeight: '50vh',
      data: {
        mode: 'create'
      }
    });
    dialogRef.afterClosed().subscribe(async result => {
      console.log('result', result);
      if (result.bundle_id) {
        const newBundle = this.hpi.ingredientBundles.filter(b => b.bundle_id === result.bundle_id);
        if (newBundle.length) {
          this.userDidSelectBundle(newBundle[0]);
        }
      }
    });
  }

  onEditBundle(bundle: IIngredientBundle) {
    const dialogRef = this.dialog.open(NewIngBundleDialogComponent, {
      width: '80vw',
      maxWidth: '500px',
      minHeight: '60vh',
      data: {
        mode: 'update',
        bundle
      }
    });
    dialogRef.afterClosed().subscribe(async result => {
      console.log('result', result);
      if (result.bundle_id) {
      }
    });
  }

  userDidSelectBundle(bundle: IIngredientBundle) {
    console.log('selected bundle', bundle);
    this.selectedBundle = bundle;
    this.createTreemap();
  }

  async onDeleteBundle(bundle: IIngredientBundle) {
    if (confirm(`Delete bundle ${bundle.bundle_name}? This cannot be undone.`)) {
      const res = await this.backend.post_route<{success: Boolean}>('delete_ing_bundle', {
        bundle_id: bundle.bundle_id
      });
      console.log('delete res', res);
      if (this.selectedBundle.bundle_id === bundle.bundle_id) {
        this.selectedBundle = null;
      }
      await this.hpi.getUserIngBundles();
    }
  }

  clickIngredient(ing: IIngredient) {
    this.hpi.selectIngredient(ing.ing_id);
    this.router.navigate(['/ingredients'])
  }

  async createTreemap() {
    const effects = await this.backend.post_route<IEffect[]>('ingredient_bundle_effects', {
      ing_ids: this.selectedBundle.ingredients.map(ing => ing.ing_id),
      period: this.hpi.period,
    });
    console.log('effect data', effects);
    function getLevelOption() {
      return [
        {
          itemStyle: {
            borderWidth: 0,
            gapWidth: 5
          }
        },
        {
          itemStyle: {
            gapWidth: 1
          }
        },
        {
          colorSaturation: [0.35, 0.5],
          itemStyle: {
            gapWidth: 1,
            borderColorSaturation: 0.6
          }
        }
      ];
    }
    const bins: IEffect[][] = [[], [], []];
    effects.forEach(eff => {
      const total_pos = eff.doc_count_science_positive + eff.doc_count_social_positive;
      const total_neg = eff.doc_count_science_negative + eff.doc_count_social_negative;
      if (total_pos > total_neg * 1.5) {
        bins[0].push(eff);
      } else if (total_neg > total_pos * 1.5) {
        bins[2].push(eff);
      } else {
        bins[1].push(eff);
      }
    })
    const binNames = ['Mostly Positive','Balanced','Mostly Negative'];
    const binColors = ['green','yellow','red'];
    const series: TreemapSeriesOption = {
      type: 'treemap',
      roam: 'pan',
      visibleMin: 1,
      label: {
        color: 'black',
      },
      itemStyle: {
        borderColor: '#fff'
      },
      levels: getLevelOption(),
      data: bins.map((bin, idx) => ({
        name: binNames[idx],
        color: [binColors[idx]],
        value: bin.reduce((partialSum, a) => partialSum + a.doc_count_science_positive + a.doc_count_science_negative + a.doc_count_social_negative + a.doc_count_social_positive, 0),
        children: bin.map(eff => ({...eff, name: eff.effect, value: eff.doc_count_science_negative + eff.doc_count_science_positive + eff.doc_count_social_negative + eff.doc_count_social_positive}))
      }))
    }
    const counts = {};

    this.treemapOptions = {
      legend: {},
      tooltip: {
        formatter: (info) => {
          const eff = info.data;
          const pos_count = eff.doc_count_science_positive + eff.doc_count_social_positive;
          const neg_count = eff.doc_count_science_negative + eff.doc_count_social_negative;
          const pos_label = 'Positive Documents'
          const neg_label = 'Negative Documents'

          return [
          '<div class="treemap-tooltip">',
            `<div class="treemap-name">${eff.effect}</div>`,
            `<div class="treemap-row"><div class="label">${pos_label}: ${pos_count}<div></div>`,
            `<div class="treemap-row"><div class="label">${neg_label}: ${neg_count}<div></div>`,
          `</div>`
          ].join('')
        }
      },
      series
    };
    this.treemapLoading = false;
  }

}

<div class="science-container" *ngLet="hpi.sessionData$ | async; let sd;">
  <ng-container *ngIf="sd?.ingredient; let sel;">
    <div class="ing-title">{{sel.ingredient | uppercase}}</div>
    <div class="score-timeline-container">
      <div class="score-container card-container">
        <div class="card-header no-header">
          <div class="card-title">Science Score<mat-icon class="tooltip-icon" matTooltip="Sentiment Score for publications involving {{sel.ingredient}}">info_outline</mat-icon></div>
        </div>
        <div class="summary-scores card-content">
          <div class="science-score summary-score">
            <app-summary-score [ingredient]="sel" [scienceOrSocial]="'science'" [orient]="'vertical'"></app-summary-score>
            <div class="score-description" *ngLet="sel?.science_score_delta as delta;">
              <ng-container *ngIf="delta > 0">
                Sentiment is trending more negative in recent publications
              </ng-container>
              <ng-container *ngIf="delta < 0">
                Sentiment is trending more postiive in recent publications
              </ng-container>
              <ng-container *ngIf="delta === 0">
                Sentiment has not changed in recent publications
              </ng-container>
            </div> 
          </div>
        </div>
      </div>
      <div class="timeline-container bordered card-container">
        <div class="card-header no-header">
          <div class="card-title">Science Documents Timeline<img matTooltip="Double click on a point to inspect documents." src="assets/info_icon.png" class="info-icon-small"></div>
        </div>
        <div class="card-content timeline-wrapper">
          <app-doc-timeline [scienceOrSocial]="'science'"></app-doc-timeline>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="treemap-docs-container">
    <div class="treemap-container card-container">
      <div class="card-header">
        <div class="card-title">Effects Deep Dive<mat-icon class="tooltip-icon" matTooltip="Effects related to {{sd?.ingredient?.ingredient}} by sentiment. Double click a box to inspect documents.">info_outline</mat-icon></div>
      </div>
      <div class="card-content">
        <app-effect-tree-map [scienceOrSocial]="'science'"></app-effect-tree-map>
      </div>
    </div>
    <div class="docs-container card-container" *ngIf="documents">
      <div class="docs-header card-header">
        <div class="card-title">
          Recent Publications <mat-icon class="tooltip-icon" matTooltip="Most recent scientific publications related to {{sd?.ingredient?.ingredient}}">info_outline</mat-icon>
        </div>
      </div>
      <div class="docs-list card-content">
        <div class="doc" *ngFor="let doc of documents">
          <div [ngClass]="{'title': true, 'with-link': doc?.title?.length > 0}" (click)="clickDoc(doc)">
            {{doc.title}}<mat-icon *ngIf="doc?.links?.length">open_in_new</mat-icon>
          </div>
          <div class="subtitle">
            <div class="journal" *ngIf="doc?.journal?.length">{{doc.journal}}</div>
            <mat-icon *ngIf="doc?.journal?.length">chevron_right</mat-icon>
            <div class="date" *ngIf="doc.date">{{doc.date | date : 'longDate'}}</div>
            <mat-icon *ngIf="doc.date">chevron_right</mat-icon>
            <div class="citations" *ngIf="doc.citations">{{doc.citations}} citations</div>
          </div>
          <div class="concerns">
            <div class="label">Effects:</div>
            <app-effects-chip-list [eff_ids]="doc?.eff_ids" [ing_id]="hpi?.ing_id" [nowrap]="true"></app-effects-chip-list>
          </div>
          <div class="abstract">{{doc.abstract}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="training-container">
  <div class="training-manual-container">
  </div>
  <ion-card>
    <ion-card-header>
      <ion-card-title>Training Manual</ion-card-title>
      <a href="https://storage.googleapis.com/hpi-app-dev.appspot.com/docs/Dashboard%20Training%20Manual_July%202023_final.pdf?Expires=1735689600&GoogleAccessId=firebase-adminsdk-qe4zt%40hpi-app-dev.iam.gserviceaccount.com&Signature=gdhfekvlLk7%2BcZjOi6lU%2F0ave7wxr4PHKbFQUOTPnnVNX5du8Q5HtT62Px9hQxoihuHLmhHuABxCMM5nfS2Rk5PeOf237%2FW1Dwg80cRPSkg4KmIkseJBAVzZKMx7IhsuvT%2FCHcEurqlb9dYGMBDsjzk360fQSsJrcbkvMo228Nt%2BFpAJ%2B%2BFNPT%2BgihAVCdOWq53g3MMK2YTdaTmb1usN8oeP4MAH28jYHyiJGDUVsMqrxumoAIU67XUhy7%2BZDvO%2FIqB0JdKwFdurlSR7ZqVZApYuh2WW%2FATEZKFDzyTRwgYSrGZyHs7D6u%2FON%2BuHLFoOZYMZTGdddM6Nf4WcugTQDw%3D%3D" target="_blank">Download the dashboard training manual</a>
    </ion-card-header>
    <ion-card-content style="height: 70vh !important;">
      <embed src="https://storage.googleapis.com/hpi-app-dev.appspot.com/docs/Dashboard%20Training%20Manual_July%202023_final.pdf?Expires=1735689600&GoogleAccessId=firebase-adminsdk-qe4zt%40hpi-app-dev.iam.gserviceaccount.com&Signature=gdhfekvlLk7%2BcZjOi6lU%2F0ave7wxr4PHKbFQUOTPnnVNX5du8Q5HtT62Px9hQxoihuHLmhHuABxCMM5nfS2Rk5PeOf237%2FW1Dwg80cRPSkg4KmIkseJBAVzZKMx7IhsuvT%2FCHcEurqlb9dYGMBDsjzk360fQSsJrcbkvMo228Nt%2BFpAJ%2B%2BFNPT%2BgihAVCdOWq53g3MMK2YTdaTmb1usN8oeP4MAH28jYHyiJGDUVsMqrxumoAIU67XUhy7%2BZDvO%2FIqB0JdKwFdurlSR7ZqVZApYuh2WW%2FATEZKFDzyTRwgYSrGZyHs7D6u%2FON%2BuHLFoOZYMZTGdddM6Nf4WcugTQDw%3D%3D" width="100%" height="100%">
    </ion-card-content>
  </ion-card>
  <div class="video-container" *ngFor="let v of videos | keyvalue : originalOrder">
    <ion-card class="video-card">
      <ion-card-header>
        <ion-card-title>
          {{v.key}}
        </ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <div class="video">
          <video [ngClass]="['video']" controls (click)="toggleVideo()" #videoPlayer>
            <source [src]="v.value" type="video/mp4"/>
            Browser not supported
          </video>
        </div>
      </ion-card-content>
    </ion-card>
  </div>
</div>
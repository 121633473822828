import { Component, OnDestroy, OnInit } from '@angular/core';
import { BackendService } from '@app/core/backend.service';
import { HpiService } from '@app/core/hpi.service';
import { IHpiDocument } from '@app/models/hpi.model';
import { EChartsOption, TreemapSeriesOption } from 'echarts';
import { distinctUntilChanged, distinctUntilKeyChanged, map, Subscription } from 'rxjs';

@Component({
  selector: 'app-social',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.scss']
})
export class SocialComponent implements OnInit, OnDestroy {


  timelineOptions: EChartsOption;
  timelineLoading = true;

  treemapOptions: EChartsOption;
  treemapLoading = true;

  documents: IHpiDocument[];

  ingParams;
  sub: Subscription;
  sub2: Subscription;

  socialScoreDelta = 0;

  constructor(
    public hpi: HpiService,
    private backend: BackendService
  ) { }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.sub2) {
      this.sub2.unsubscribe();
    }
  }

  async ngOnInit() {
    this.sub = this.hpi.sessionData$.subscribe(async sd => {
      if (!sd.ingredient) {return;}
      this.ingParams = sd.params;
      this.socialScoreDelta = sd.ingredient.social_score_delta;
      await this.constructTimeline();
      await this.constructBox();
      this.backend.post_route<IHpiDocument[]>('ingredient_documents', {
        ing_id: sd.ingredient.ing_id,
        ingredient: sd.ingredient.ingredient,
        period: sd.params.period,
        science_or_social: 'social'
      }).then(res => {
        this.documents = res;
      })
    });
  }

  async constructTimeline() {
    // const timelineResponse = await this.hpi.docTimeline('social');
    // const socialNegativeValues = timelineResponse.social_negative;
    // const socialPositiveValues = timelineResponse.social_positive;
    // const axisValues = timelineResponse.axis_values;
    const axisValues = [];
    this.timelineOptions = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },
      legend: {
        data: ['News']
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          boundaryGap: false,
          data: axisValues
        }
      ],
      yAxis: [
        {
          type: 'value'
        }
      ],
      series: [
        {
          name: 'Articles (Negative)',
          type: 'line',
          stack: 'Total',
          label: {
            show: true,
            position: 'top'
          },
          areaStyle: {},
          emphasis: {
            focus: 'series'
          },
          // data: socialNegativeValues.map(d => d[1])
        },
        {
          name: 'Articles (Positive)',
          type: 'line',
          stack: 'Total',
          label: {
            show: true,
            position: 'top'
          },
          areaStyle: {},
          emphasis: {
            focus: 'series'
          },
          // data: socialPositiveValues.map(d => d[1])
        },
      ]
    };
    this.timelineLoading = false;
  }

  async constructBox() {
    // const docs = this.hpi.selectedIngredient$.getValue().documents.filter(d => d.science_or_social === 'social');
    const docs = [];
    const counts = {}
    docs.forEach(doc => {
      const c = doc.concern;
      if (!counts[c]) {
        counts[c] = 1
      } else {
        counts[c] += 1
      }
    });
    const series: TreemapSeriesOption = {
      type: 'treemap',
      roam: 'pan',
      data: Object.entries(counts as {[k: string]: number}).map(([name, value]) => {
        return {name, value}
      }).sort((a, b) => b.value - a.value)
    }

    this.treemapOptions = {
      // title: {
      //   text: 'Concerns Deep Dive (hover for detail)'
      // },
      series
    };
    this.treemapLoading = false;
  }

  clickDoc(doc: IHpiDocument) {
    if (doc.links.length) {
      window.open(doc.links[0], '_blank')
      // window.open(`https://twitter.com/Twitter/status/${doc.doc_id}`, '_blank');
    }
  }
}

import { ValidatorFn, AbstractControl } from '@angular/forms';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';
const phoneNumberUtil = PhoneNumberUtil.getInstance();
export function PhoneValidator(regionCode: string = 'US'): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control.value || control.value === '') {
      return null;
    }
    let validNumber = false;
    try {
      const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(
        control.value, regionCode
      );
      validNumber = phoneNumberUtil.isValidNumber(phoneNumber);
      phoneNumberUtil.format(phoneNumber, PhoneNumberFormat.E164);
      // if(control.value !== formatted) control.setValue(formatted);
    } catch (e) { 
    }

    return validNumber ? null : { invalidNumber: { value: control.value } };
  };
}

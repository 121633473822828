<div class="support-tab-container">
  <div class="support-box" *ngIf="!showSuccess">
    <div class="support-box-left">
      <div class="support-form-container">
        <div class="support-title">
          Request Form
        </div>
        <form class="support-form" [formGroup]="requestForm">
          <label class="section-label">Category</label>
          <mat-radio-group formControlName="category" class="support-category">
            <mat-radio-button [value]="o" *ngFor="let o of categoryOptions">{{o}}</mat-radio-button>
          </mat-radio-group>
          <label class="section-label">Description</label>
          <textarea formControlName="description" class="support-input" matInput placeholder="Describe the reason for your request"></textarea>
          <input type="file" class="file-input-hidden" #fileInput>
          <div class="file-input-container" (click)="fileInput.click(); ">
            <img src="assets/attach_45.png" class="file-input-icon">
            <div class="file-input-label">Attach a file</div>
          </div>
        </form>
        <div class="support-actions">
          <div class="support-clear-container" (click)="onClear()">
            <div class="support-clear-wrapper">
              <div class="support-clear-label" >Clear</div>
            </div>
          </div>
          <div class="support-submit-container" (click)="onSubmit()">
            <div class="support-submit-wrapper">
              <div class="support-submit-label">Submit</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="support-box-right">
      <img src="assets/support_guy.png">
    </div>
  </div>
  <div class="success-box" *ngIf="showSuccess">
    <div class="success-text">
      Thank you for submitting your request!
    </div>
    <button class="back-button" (click)="onClear(); showSuccess=false;">Back</button>
  </div>
</div>
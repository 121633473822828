import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/auth.service';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  email = '';
  constructor(
    private authService: AuthService,
    private toaster: ToastController,
    private router: Router,
  ) { }

  ngOnInit() {}

  async onSubmit() {
    await this.authService.onResetPassword(this.email);
    const toast = await this.toaster.create({
      message: 'Check your email for a password reset link',
      duration: 2000
    });
    toast.present();
    setTimeout(() => {
      this.router.navigate(['/login']);
    }, 2000)
  }

}

<div class="summary-container">
  <ng-container *ngLet="hpi.selectedIngredient$ | async; let sel;">
    <div class="ing-title">{{sel?.ingredient | uppercase}}</div>
    <div class="summary-metrics">
      <div class="summary-scores-container">
        <div class="summary-scores bordered card-container">
          <div class="science-score summary-score">
            <div class="card-header no-header">
              <div class="card-title">Science Score <img matTooltip="Sentiment Score for publications involving {{sel?.ingredient}}" src="assets/info_icon.png" class="info-icon-small"></div>
            </div>
            <app-summary-score [ingredient]="sel" [scienceOrSocial]="'science'"></app-summary-score>
          </div>
          <div class="divider"></div>
          <div class="social-score summary-score ">
            <div class="card-header no-header">
              <div class="card-title">Market Score <img matTooltip="Sentiment Score for news articles related to {{sel?.ingredient}}" src="assets/info_icon.png" class="info-icon-small"></div>
            </div>
            <app-summary-score [ingredient]="sel" [scienceOrSocial]="'social'"></app-summary-score>
          </div>
        </div>
      </div>
      <div class="summary-new card-container">
        <div class="card-header no-header">
          <div class="card-title">
            {{hpi.period === 'All' ? 'Documents' : 'New Last Year'}}
            <mat-icon class="tooltip-icon" matTooltip="Total publications/news articles related to {{sel?.ingredient}} over the chosen period">info_outline</mat-icon>
          </div>
        </div>
        <div class="summary-new-values card-content">
          <div class="new-v">
            <div class="value">{{sel?.doc_count_science | tableValue: 'doc_count'}}</div>
            <div class="label">Science Documents</div>
            <div class="new-v-footer">
              <div class="neg score-5" [matTooltip]="(sel?.doc_count_science_negative | tableValue: 'doc_count') + ' Negative Publications'">{{sel?.doc_count_science_negative | tableValue: 'doc_count'}}</div>
              <div class="neutral score-2" [matTooltip]="((sel?.doc_count_science - sel?.doc_count_science_negative - sel?.doc_count_science_positive ) | tableValue: 'doc_count') + ' Neutral Publications'">{{(sel?.doc_count_science - sel?.doc_count_science_negative - sel?.doc_count_science_positive ) | tableValue: 'doc_count'}}</div>
              <div class="pos score-1" [matTooltip]="(sel?.doc_count_science_positive | tableValue: 'doc_count') + ' Positive Publications'">{{sel?.doc_count_science_positive | tableValue: 'doc_count'}}</div>
            </div>
          </div>
          <div class="new-v left-bordered">
            <div class="value">{{sel?.doc_count_social | tableValue : 'doc_count'}}</div>
            <div class="label">News Articles</div>
            <div class="new-v-footer">
              <div class="neg score-5" [matTooltip]="(sel?.doc_count_social_negative | tableValue: 'doc_count') + ' Negative News Articles'">{{sel?.doc_count_social_negative | tableValue: 'doc_count'}}</div>
              <div class="neutral score-2" [matTooltip]="((sel?.doc_count_social - sel?.doc_count_social_negative - sel?.doc_count_social_positive ) | tableValue: 'doc_count') + ' Neutral News Articles'">{{(sel?.doc_count_social - sel?.doc_count_social_negative - sel?.doc_count_social_positive ) | tableValue: 'doc_count'}}</div>
              <div class="pos score-1" [matTooltip]="(sel?.doc_count_social_positive | tableValue: 'doc_count') + ' Positive News Articles'">{{sel?.doc_count_social_positive | tableValue: 'doc_count'}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="summary-timeline-container card-container">
    <div class="card-header">
      <div class="card-title">{{timelineTitle}}<img matTooltip="Double click on a point to inspect documents" src="assets/info_icon.png" class="info-icon-small"></div>
    </div>
    <div class="card-content timeline-wrapper">
      <app-doc-timeline [scienceOrSocial]="'both'"></app-doc-timeline>
    </div>
  </div>

  <div class="summary-effects bordered card-container">
    <div class="card-header">
      <div class="card-title">Ingredient Effects<img matTooltip="Effects linked to the chosen ingredient, expand to see effect definition" src="assets/info_icon.png" class="info-icon-small"></div>
    </div>
    <div class="card-content">
      <app-effects-table></app-effects-table>
    </div>
  </div>


  <!-- <div class="summary-documents bordered card-container">
    <div class="card-header">
      <div class="card-title">Number of Documents Analyzed</div>
    </div>
    <div class="card-content">

    </div>
  </div> -->
</div>

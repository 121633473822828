import { Component } from '@angular/core';

@Component({
  selector: 'app-doc-table',
  templateUrl: './doc-table.component.html',
  styleUrls: ['./doc-table.component.scss']
})
export class DocTableComponent {

}

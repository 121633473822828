<div
  *ngIf="timelineOptions"
  #timeline 
  echarts
  (chartInit)="onChartInit($event)"
  [loading]="timelineLoading"
  style="height: 100%; width: 100%;"
  [options]="timelineOptions"
  >
</div>
